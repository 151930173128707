import {
  IconKeno,
  IconLive,
  IconSlot,
  IconSport,
} from "assets/images/homepage";
import { PATH } from "./path";

export const LIST_MENU_GAMES = [
  {
    name: "sport",
    to: "/",
    key: "sports-book",
  },

  {
    name: "Live Casino",
    to: PATH.LiveCasino,
    key: "live-casino",
  },

  {
    name: "Fishing",
    to: "/fish",
    key: "fish",
  },
  {
    name: "Lottery",
    to: "/lottery",
    key: "lottery",
  },
  {
    name: "game",
    to: PATH.SlotGame,
    key: "slot",
  },

  {
    name: "Password",
    to: PATH.changePassword,
    key: "password",
  },

  {
    name: "Promotion",
    to: "/promotion",
    key: "promotion",
  },
];

export const LIST_MENU_GAMES_MOBILE = [
  {
    name: "Sports",
    to: `/m${PATH.SPORT}`,
    key: "sports-book",
    icon: IconSport,
  },
  {
    name: "Live Casino",
    to: PATH.LiveCasino,
    key: "live-casino",
    icon: IconLive,
  },
  {
    name: "Lottery",
    key: "lottery",
    icon: IconKeno,
    to: `/m${PATH.Lottery}`,
  },
  {
    name: "Games",
    key: "slot",
    icon: IconSlot,
    to: `/m${PATH.SlotGame}`,
  },
];
