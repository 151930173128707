import { useQueryClient } from "@tanstack/react-query";
import { languages } from "constants/languages";
import { useTranslation } from "react-i18next";
interface Props {
  disable?: boolean;
}
function SelectLanguage({ disable }: Props) {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const onChangeLanguage = (value: string) => {
    if (disable) return;
    localStorage.setItem("i18nLng", value);
    i18n.changeLanguage(value);
    queryClient.refetchQueries();
    const element = document.getElementsByTagName("html");
    if (element) {
      element[0].lang = value;
    }
    // window.location.reload();
  };

  return (
    <select
      value={i18n.language}
      onChange={(e) => onChangeLanguage(e.target.value)}
    >
      {languages.map((item, index) => {
        return (
          <option key={index + item.code} value={item.code}>
            {item.name2 || item.name}
          </option>
        );
      })}
    </select>
  );
}

export default SelectLanguage;
