/** @jsxImportSource @emotion/react */

import MenuGameMobile from "components/MenuGameMobile";
import { HomepageStyle } from "./style.index";
import { LIST_GAME_MOBILE, M_LIST_LOTTERY_GAMES } from "constants/games";
import CardGameMobile from "components/CardGameMobile";
import { useState } from "react";

const Homepage = () => {
  const [typeGame, setTypeGame] = useState<string>("");

  const renderContent = () => {
    if (typeGame === "live") {
      return LIST_GAME_MOBILE.filter((typeGame) => typeGame.type).map(
        (item, index) => <CardGameMobile key={index} {...item} />
      );
    }
    if (typeGame === "lottery") {
      return M_LIST_LOTTERY_GAMES.filter((typeGame) => typeGame.type).map(
        (item, index) => <CardGameMobile key={index} {...item} />
      );
    }
    return LIST_GAME_MOBILE.map((item, index) => (
      <CardGameMobile key={index} {...item} />
    ));
  };

  return (
    <div css={HomepageStyle.wrapper}>
      <MenuGameMobile setTypeGame={setTypeGame} />

      <div css={HomepageStyle.content}>
        <>{renderContent()}</>
      </div>
    </div>
  );
};

export default Homepage;
