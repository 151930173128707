/** @jsxImportSource @emotion/react */

import { LIST_CASINO_GAMES } from "constants/games";
import { LiveCasinoPageStyled } from "./style.index";
import Container from "components/common/Container";
import HelmetCustom from "components/common/HelmetCustom";
import handleLoginGame from "helpers/handleLoginGame";
import { USERNAME } from "constants/user";

const LiveCasinoPage = () => {
  const username = localStorage.getItem(USERNAME) || "";
  return (
    <Container>
      <HelmetCustom title="GT855" />
      <div css={LiveCasinoPageStyled.wrapper}>
        {LIST_CASINO_GAMES.map((item, index) => (
          <div
            onClick={() => handleLoginGame(item.data, username)}
            css={LiveCasinoPageStyled.item}
            key={index}
          >
            <img src={item.img} alt="" />
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default LiveCasinoPage;
