import { css } from "@emotion/react";

export const DropdownGameStyle = {
  wrapper: (isActive: boolean) => css`
    transition: all 0.6s ease-in-out;
    height: ${isActive ? "155px" : 0};
    position: absolute;
    width: 100%;
    top: 46px;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    z-index: 9;
    background-color: white;
    overflow: hidden;
  `,
  item: css`
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: auto;

    cursor: pointer;

    width: 240px;
    img {
      width: 133px;
    }

    span {
      text-align: center;
      color: #800080;
      padding-top: 10px;
      font-weight: bold;
    }
  `,
};
