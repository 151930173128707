/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import BalancePopup from "components/Mobile/BalancePopup";
import BottomTabs from "components/Mobile/BottomTabs";
import MenuSideBar from "components/Mobile/MenuSideBar";
import ModalUse from "components/ModalUse";
import Loading from "components/common/Loading";
import { PATH } from "constants/path";
import { USERNAME } from "constants/user";
import { Fragment, Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const DefaultLayoutMobile = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isLogin = localStorage.getItem(USERNAME);
    if (!isLogin) return navigate(PATH.HomeMobile + PATH.Login);
  }, [navigate]);

  return (
    <Fragment>
      <main
        css={css`
          position: fixed;
          width: 100vw;
          height: 100vh;
          z-index: 9;
          display: flex;
          flex-direction: column;
          background-color: #f3f6f4;
          color: #666;
          padding-bottom: var(--bottom-height);

          &:-webkit-scrollbar {
            display: none;
          }
        `}
      >
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </main>
      <ModalUse />
      <BottomTabs />
      <MenuSideBar />
      <BalancePopup />
    </Fragment>
  );
};

export default DefaultLayoutMobile;
