export interface LanguagesProps {
  code: string;
  name: string;
  name2?: string;
  id?: number;
}
export const languages: LanguagesProps[] = [
  {
    code: "EN-US",
    name: "ENGLISH",
    name2: "English",
    id: 1,
  },
  {
    code: "ZH-CN",
    name: "CHINESE (SIMPLIFIED)",
    id: 2,
  },
  {
    code: "EN-CA",
    name: "CHINESE (TRADITIONAL)",
    id: 3,
  },
  {
    code: "TH-TH",
    name: "THAI",
    id: 6,
  },
  {
    code: "EN-IE",
    name: "VIETNAM",
    id: 7,
  },
  {
    code: "EN-TT",
    name: "KOREAN",
  },
  {
    code: "UR-PK",
    name: "TURKISH",
  },
  {
    code: "EN-AU",
    name: "INDONESIA",
  },
  {
    code: "MY-MY",
    name: "BURMESE",
  },
];
