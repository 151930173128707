/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { ResultTabStyle } from "./style.index";
import Normal from "./Normal";
import OutRight from "./OutRight";
import { Select } from "antd";

const tabs = [
  { id: 0, label: "Normal", component: <Normal /> },
  { id: 1, label: "Outright", component: <OutRight /> },
];

const ResultTab = () => {
  const [activeTab, setActiveTab] = useState<number>(tabs[0].id);

  const onTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  return (
    <div css={ResultTabStyle.wrapper}>
      <div css={ResultTabStyle.select}>
        <Select
          defaultValue="sports"
          options={[{ value: "sports", label: "SPORTS" }]}
        />
      </div>

      <div css={ResultTabStyle.tabs.index}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => onTabClick(tab.id)}
            css={ResultTabStyle.tabs.item(activeTab === tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>

      {tabs.find((tab) => tab.id === activeTab)?.component}
    </div>
  );
};

export default ResultTab;
