import { css } from "@emotion/react";

export const RunningTabStyle = {
  wrapper: css`
    height: 29px;
    padding: 8px 15px;
    background-color: white;
    display: flex;
  `,

  btn: (isActive: boolean) => css`
    width: 25%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #137433;
    background-color: ${isActive ? "#567fce" : ""};
    font-size: 12px;

    &:first-of-type {
      color: ${isActive ? "white" : "#9c7500"};

      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    &:nth-of-type(2) {
      color: ${isActive ? "white" : "#004a18"};
    }
    &:last-of-type {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    color: ${isActive ? "white" : "#980000"};
  `,
};
