/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";

function PublicLayoutMobile() {
  return (
    <Fragment>
      <main
        css={css`
          display: flex;
          flex-direction: column;
          background-color: #e0e0e0;
          color: #666;
        `}
      >
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </main>
    </Fragment>
  );
}

export default PublicLayoutMobile;
