/** @jsxImportSource @emotion/react */

import React from "react";
import { ResultTabStyle } from "../style.index";

const OutRight = () => {
  return (
    <React.Fragment>
      <div css={ResultTabStyle.selectGroup}>
        <select defaultValue={"today"} name="" id="">
          <option value="today">TODAY</option>
          <option value="yesterday">YESTERDAY</option>
          <option value="04/02">04/02</option>
          <option value="03/02">03/02</option>
          <option value="02/02">02/02</option>
          <option value="01/02">01/02</option>
          <option value="31/01">31/01</option>
          <option value="30/01">30/01</option>
        </select>
        <select defaultValue={"Soccer"} name="" id="">
          <option>Soccer - Outright</option>
          <option>Basketball - Outright</option>
          <option>Olympic - Outright</option>
          <option>US Football - Outright</option>
          <option>Baseball - Outright</option>
          <option>Ice Hockey - Outright</option>
          <option>Pool/Snooker - Outright</option>
          <option>Rugby - Outright</option>
          <option>Tennis - Outright</option>
          <option>Darts - Outright</option>
          <option>BOXING - Outright</option>
          <option>Motor Sports - Outright</option>
          <option>Golf - Outright</option>
          <option>Futsal - Outright</option>
          <option>Badminton - Outright</option>
          <option>Water Polo - Outright</option>
          <option>Table Tennis - Outright</option>
          <option>Cricket - Outright</option>
          <option>Volleyball - Outright</option>
          <option>Handball - Outright</option>
          <option>Beach Soccer - Outright</option>
          <option>Winter Sport - Outright</option>
          <option>Cycling - Outright</option>
          <option>Athletics - Outright</option>
          <option>E-SPORTS - Outright</option>
        </select>
      </div>

      <select css={ResultTabStyle.selectHeading}>
        <option value="All League">All League</option>
      </select>

      <div css={ResultTabStyle.outRightResult.index}>
        <div css={ResultTabStyle.outRightResult.heading}>Note :</div>
        <p>Please note that the displayed time is based on GMT +8 hours</p>
      </div>
    </React.Fragment>
  );
};

export default OutRight;
