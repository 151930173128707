/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import { HeaderPageStyle } from "./style";
import { Fragment } from "react";

type Props = {
  title: string;
  position?: string;
};

function HeaderPage({ title, position = "center" }: Props) {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div css={HeaderPageStyle.index(position)}>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left"></i>
        <span>{title}</span>
        <i />
      </div>
      <div style={{ height: 40 }} />
    </Fragment>
  );
}

export default HeaderPage;
