/** @jsxImportSource @emotion/react */

import DropdownGame from "components/DropdownGame";
import { LIST_MENU_GAMES } from "constants/list-menu-game";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuGameStyle } from "./style.index";
import { useTranslation } from "react-i18next";

const MenuGame = () => {
  const { t } = useTranslation("game");
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const onTo = (id: string, to: string) => {
    if (!(id === "fish" || id === "promotion")) {
      navigate(to);
      return;
    }
    return;
  };

  return (
    <div css={MenuGameStyle.self}>
      <div css={MenuGameStyle.wrapper}>
        {LIST_MENU_GAMES.map((item) => {
          const isActive = item.to === location.pathname;
          return (
            <div
              onClick={() => onTo(item.key, item.to)}
              onMouseEnter={() => {
                item.key === "live-casino" && setIsShowDropdown(true);
              }}
              onMouseLeave={() => setIsShowDropdown(false)}
              key={item.key}
              css={MenuGameStyle.item(isActive)}
            >
              {t(item.name)}
            </div>
          );
        })}
      </div>
      <DropdownGame
        setIsHidden={() => setIsShowDropdown(false)}
        setIsShow={() => setIsShowDropdown(true)}
        isActive={isShowDropdown}
      />
    </div>
  );
};

export default MenuGame;
