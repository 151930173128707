import { css } from "@emotion/react";

export const BottomTabsStyle = {
  index: css`
    background-color: #567fce;
    margin-bottom: -1px;
    right: 0;
    left: 0;
    bottom: 0;
    height: var(--bottom-height);
    position: fixed;
    z-index: 10;
    -webkit-box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.16);
    box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.16);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    .list-menu {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0 10px;
      width: 100%;
      .menu-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        &.balance-active {
          background-color: #ececec;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
        i {
          width: 24px;
          height: 28px;
          text-align: center;
          font-size: 24px;
          color: #fff;
          vertical-align: bottom;
        }
        span {
          font-weight: 400;
          line-height: 1.42857143;
          text-align: center;
          white-space: nowrap;
          font-size: 10px;
          color: #ffffff;
          text-transform: uppercase;
          margin-top: 4px;
        }
      }
    }
  `,
};
