import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import {
  GetProfilePayload,
  GetProfileResponse,
  MemberBalancePayload,
  MemberBalanceResType,
  UpdateMemberDetailPayload,
  UpdateProfileResponse,
} from "types/user";

export const UserApi = {
  async getProfile(payload: GetProfilePayload) {
    const response = await axiosRequest.post<GetProfileResponse>(
      "/",
      payloadHelper("MemberDetail", payload.username, payload)
    );
    return response.data;
  },

  async getMemberBalance(typename: string, payload: MemberBalancePayload) {
    const response = await axiosRequest.post<MemberBalanceResType>(
      "",
      payloadHelper("GetBalanceDV", typename, payload)
    );

    return response.data;
  },

  async updateMemberDetail(
    username: string,
    payload: UpdateMemberDetailPayload
  ) {
    const { data } = await axiosRequest.post<UpdateProfileResponse>(
      "/",
      payloadHelper("UpdateMemberDetail", username, payload)
    );
    return data;
  },
};
