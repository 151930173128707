/** @jsxImportSource @emotion/react */

import { useMutation, useQuery } from "@tanstack/react-query";
import ModalWrapper from "../../../../components/Mobile/ModalWrapper";
import { queryClient } from "index";
import ModalHeader from "../../../../components/Mobile/ModalWrapper/ModalHeader";
import { ModalChangePasswordStyle } from "./style";
import GroupButton from "../../../../components/Mobile/ModalWrapper/GroupButton";
import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { AuthApi } from "api/auth";
import { USERNAME } from "constants/user";
import { REGEX_PASSWORD } from "constants/regex";
interface Error {
  key: string;
  value: string;
}
function ModalChangePassword() {
  const { data: isOpen } = useQuery<boolean>({
    queryKey: ["open_change_password"],
    initialData: false,
  });
  const password = useRef<HTMLInputElement>(null);
  const newPassword = useRef<HTMLInputElement>(null);
  const confirmPassword = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<Error | null>(null);
  const { isLoading, mutate } = useMutation({
    mutationKey: ["ChangePassword"],
    mutationFn: AuthApi.changePassword,
    onSuccess: (data) => {
      message.success(data.msg);
      setError(null);
    },
    onError: (error) => {
      setError(null);
      message.error(error + "");
    },
  });

  useEffect(() => {
    if (!isOpen) return;
    setError(null);
    if (password.current) {
      password.current.value = "";
    }
    if (newPassword.current) {
      newPassword.current.value = "";
    }
    if (confirmPassword.current) {
      confirmPassword.current.value = "";
    }
  }, [isOpen]);

  const handleChangePassword = async () => {
    const passwordValue = password.current?.value;
    const newValue = newPassword.current?.value;
    const confirmValue = confirmPassword.current?.value;
    if (!passwordValue)
      return setError({
        key: "old_password",
        value: "Please Enter Password",
      });
    if (!newValue)
      return setError({
        key: "password",
        value: "Please Enter Password",
      });
    if (!confirmValue)
      return setError({
        key: "confirm_password",
        value: "Please Enter Confirm Password",
      });
    if (newValue !== confirmValue) {
      return setError({
        key: "confirm_password",
        value: "Verify password does not match the password.",
      });
    }
    if (
      !REGEX_PASSWORD.test(passwordValue) ||
      !REGEX_PASSWORD.test(newValue) ||
      !REGEX_PASSWORD.test(confirmValue)
    ) {
      setError(null);
      return alert("Invalid old password or new password or confirm password");
    }
    try {
      mutate({
        username: localStorage.getItem(USERNAME) as string,
        oldpass: passwordValue,
        password: newValue,
        passconf: confirmValue,
      });
    } catch (error) {
      message.error(error + "");
    }
  };
  return (
    <ModalWrapper
      open={isOpen}
      onClose={() => queryClient.setQueryData(["open_change_password"], false)}
    >
      <ModalHeader title="Password" />
      <form css={ModalChangePasswordStyle.form}>
        <input
          onFocus={() => {
            if (error && error.key === "old_password") {
              setError(null);
            }
          }}
          ref={password}
          placeholder="Old Password"
          type="password"
        />
        {error && error.key === "old_password" && (
          <span className="text-danger">{error?.value}</span>
        )}
        <input
          onFocus={() => {
            if (error && error.key === "password") {
              setError(null);
            }
          }}
          ref={newPassword}
          placeholder="New Password"
          type="password"
        />
        {error && error.key === "password" && (
          <span className="text-danger">{error?.value}</span>
        )}
        <input
          onFocus={() => {
            if (error && error.key === "confirm_password") {
              setError(null);
            }
          }}
          ref={confirmPassword}
          placeholder="Confirm Password"
          type="password"
        />
        {error && error.key === "confirm_password" && (
          <span className="text-danger">{error?.value}</span>
        )}
      </form>
      <GroupButton
        paddingBottom={15}
        data={[
          {
            title: "CANCEL",
            onPress: () => {
              queryClient.setQueryData(["open_change_password"], false);
            },
            background: "orange",
            color: "#fff",
          },
          {
            title: "OK",
            onPress: () => {
              handleChangePassword();
            },
            loading: isLoading,
            background: "#31c24f",
            color: "#fff",
          },
        ]}
      />
    </ModalWrapper>
  );
}

export default ModalChangePassword;
