import { css } from "@emotion/react";
import { ImageArrow } from "assets/images/modals";

export const ModalUseStyle = {
  wrapper: (active: boolean) => css`
    background-color: white;
    height: 100vh;
    width: 100vw;

    position: fixed;
    z-index: 12;

    display: ${active ? "block" : "none"};
  `,

  mask: css`
    background-color: black;
    opacity: 0.5;
    height: 100vh;
  `,

  modal: css`
    position: absolute;
    height: 410px;
    width: 274px;
    top: 43.5%;
    left: 50%;
    margin-top: -195px;
    margin-left: -137px;
    background-color: #fafafa;
    -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
    border-radius: 2px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,

  prev: (hidden: boolean) => css`
    left: 10px;
    background-position: top;
    display: ${hidden ? "none" : "block"};
  `,

  control: css`
    top: 50%;
    width: 16px;
    height: 40px;

    position: absolute;
    background-image: url(${ImageArrow});
    background-repeat: no-repeat;
    background-size: 16px;
  `,

  next: (hidden: boolean) => css`
    right: 10px;
    background-position: bottom;

    display: ${hidden ? "none" : "block"};
  `,

  footer: css`
    height: 48.4px;

    margin-bottom: 10px;
  `,

  progress: {
    index: css`
      margin-bottom: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
    `,
    dots: (active: boolean) => css`
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${active ? "#FAFAFA" : "rgba(0, 0, 0, 0.12)"};
      border: 2px solid ${active ? "#137D33" : "#fafafa"};
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
    `,
  },

  btn: css`
    text-align: right;

    margin-right: 20px;

    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    color: #137d33;
    text-transform: uppercase;
  `,
};
