import {
  ImageDream,
  ImageFishing,
  ImageAFB,
  // ImageKeno,
  // ImagePoker,
  ImageSA,
  ImageSE,
  ImageSportGame,
  ImageWM,
  ImageLotto,
} from "assets/images/games";
import { SubGameType } from "types/game";

export const LIST_CASINO_GAMES = [
  {
    img: ImageAFB,
    name: "AFB CASINO",
    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "AFBC",
    },
  },
  {
    img: "https://d2dgys6tg64hrs.cloudfront.net/w9/img/sa-casino.webp",
    name: "SA GAMING",

    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "SA",
    },
  },
  {
    img: "https://d2dgys6tg64hrs.cloudfront.net/w9/img/wm-casino.webp",
    name: "WM GAMING",

    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "WM",
    },
  },
  {
    img: ImageDream,
    name: "DG CASINO",

    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "DG",
    },
  },
  {
    img: ImageSE,
    name: "AE CASINO",

    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "SX",
    },
  },
];

export const LIST_LOTTERY_GAMES = [
  {
    img: ImageLotto,
    name: "LOTTERY",

    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LT",
      provider_code: "LTTO",
    },
  },
];

export const M_LIST_LOTTERY_GAMES = [
  {
    name: "LOTTERY",
    img: ImageLotto,
    href: "",
    type: "lottery",
    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LT",
      provider_code: "LTTO",
    },
  },
];

interface TypeListGameMobile {
  name: string;
  img: string;
  href: string;
  type?: string;
  background?: string;
  data?: SubGameType;
  fit?: string;
}

export const LIST_GAME_MOBILE: TypeListGameMobile[] = [
  {
    name: "Sports",
    img: ImageSportGame,
    href: "",
  },
  {
    name: "AFB CASINO",
    img: ImageAFB,
    href: "",
    type: "live",
    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "AFBC",
    },
  },
  {
    name: "SA CASINO",
    img: ImageSA,
    href: "",
    type: "live",
    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "SA",
    },
  },
  {
    name: "WM CASINO",
    img: ImageWM,
    href: "",
    type: "live",
    background: "#a9c1b1",
    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "WM",
    },
  },
  {
    name: "DREAM CASINO",
    img: ImageDream,
    href: "",
    type: "live",
    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "DG",
    },
  },
  {
    name: "AE CASINO",
    img: ImageSE,
    href: "",
    type: "live",
    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LC",
      provider_code: "SX",
    },
  },

  {
    name: "Lottery",
    img: ImageLotto,
    href: "",
    data: {
      featured: "",
      game_code: "",
      name: "",
      new_arrive: "",
      picture: "",
      type: "LT",
      provider_code: "LTTO",
    },
  },
  // {
  //   name: "Keno",
  //   img: ImageKeno,
  //   href: "",
  // },
  // {
  //   name: "We1Poker",
  //   img: ImagePoker,
  //   href: "",
  //   fit: "contain",
  // },
  {
    name: "Fishing",
    img: ImageFishing,
    href: "",
  },
];
