import { css } from "@emotion/react";

export const StatementTabStyle = {
  wrapper: css`
    margin: 8px;
    background-color: white;

    p {
      padding: 8px 12px;
      font-size: 14px;
      color: black;
    }
  `,

  heading: css`
    color: #333;

    padding: 12px 12px 0 12px;

    font-size: 16px;
  `,
};
