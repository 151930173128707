/** @jsxImportSource @emotion/react */

import { StatementTabStyle } from "./style.index";

const StatementTab = () => {
  return (
    <div css={StatementTabStyle.wrapper}>
      <div css={StatementTabStyle.heading}>Note :</div>
      <p>Please note that the displayed time is based on GMT +8 hours</p>
    </div>
  );
};

export default StatementTab;
