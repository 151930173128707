import { agbalance, agbet, agstatement } from "assets/images";
import { PATH } from "constants/path";

export const listMenus = [
  {
    name: "BET",
    path: PATH.HomeMobile,
    icon: <img src={agbet} alt="" />,
  },
  {
    name: "Balance",
    path: PATH.HomeMobile + "/balance",
    icon: <img src={agbalance} alt="" />,
  },
  {
    name: "Statement",
    path: PATH.HomeMobile + PATH.BetList + "?tab=statement",
    icon: <img src={agstatement} alt="" />,
  },
  {
    name: "Menu",
    path: PATH.HomeMobile + "/menu",
    icon: <i className="fa-solid fa-bars"></i>,
  },
];
