/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import { TabTitleStyle } from "./style.index";
import { PATH } from "constants/path";

interface Props {
  name: string;
  isActive: boolean;
  href: string;
}
const TabTitle = ({ name, isActive, href }: Props) => {
  const navigate = useNavigate();

  const onChangeTab = (href: string) => {
    navigate(PATH.HomeMobile + PATH.BetList + `?tab=${href}`);
  };

  return (
    <div css={TabTitleStyle.wrapper}>
      <div onClick={() => onChangeTab(href)} css={TabTitleStyle.item(isActive)}>
        {name}
      </div>
    </div>
  );
};

export default TabTitle;
