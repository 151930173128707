import { css } from "@emotion/react";

export const NormalResultStyle = {
  wrapper: css`
    border-radius: 2px;
    margin: 8px 0;

    border-bottom-color: rgba(0, 0, 0, 0.12);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: rgba(0, 0, 0, 0.87);

    box-shadow: 0px 1px 1.5px rgba(0, 0, 0, 0.12);

    &:nth-of-type(even) {
      .heading {
        background-color: #d6dbd7;
      }
    }
  `,

  heading: (isActive: boolean) => css`
    position: relative;
    font-size: 14px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #efefef;

    padding: 14px 0;

    span {
      padding-left: 12px;
      font-size: 14px;
    }
    img {
      height: 12px;
      margin-right: 10px;
      transform: ${isActive ? "rotate(180deg)" : ""};
    }
  `,

  content: (isActive: boolean) => css`
    background-color: white;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    min-height: 48px;
    color: rgba(0, 0, 0, 0.87);

    display: ${isActive ? "block" : "none"};

    &:not(:last-of-type) {
      border-bottom: 1px solid #ccc;
    }
  `,

  title: {
    index: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,

    time: css`
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
    `,

    detail: css`
      margin-right: 10px;

      span {
        font-weight: bold;
        &:first-of-type {
          margin-right: 20px;
          color: red;
        }
      }
    `,
  },

  match: css`
    font-weight: normal !important;
    font-size: 13px;
  `,
};
