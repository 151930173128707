/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import Marquee from "react-fast-marquee";
import { HeaderStyle } from "./style.index";

import { useQuery } from "@tanstack/react-query";
import { AnnouncementApi } from "api/announcement";
import { AnnouncementResponseType } from "api/announcement/type";
import { Logo } from "assets/images";
import SelectLanguage from "components/SelectLanguage";
import { QueryKeys } from "constants/queryKeys";
import { USERNAME } from "constants/user";
import { handleLogout } from "helpers/handleLogout";

const Header = () => {
  const username = localStorage.getItem(USERNAME);
  const { data: response } = useQuery<AnnouncementResponseType>([QueryKeys.Announcement], {
    queryFn: async () => {
      return await AnnouncementApi.getAnnouncement({});
    },
  });
  const data = response?.data;

  return (
    <div css={HeaderStyle.wrapper}>
      <div css={HeaderStyle.logo}>
        <img src={Logo} alt="" />
      </div>
      <div css={HeaderStyle.center.index}>
        <div css={HeaderStyle.center.name}>
          Welcome, <p>{username}</p>
        </div>
        <div css={HeaderStyle.center.notify}>
          <Marquee pauseOnHover autoFill>
            {data?.map((item, key) => (
              <span className="item" key={key}>
                {item.title}
              </span>
            ))}
          </Marquee>
        </div>
      </div>
      <div css={HeaderStyle.right}>
        <SelectLanguage />
        <Button onClick={handleLogout}>Logout</Button>
      </div>
    </div>
  );
};

export default Header;
