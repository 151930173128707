/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import { listMenus } from "./list";
import { BottomTabsStyle } from "./style";
import { PATH } from "constants/path";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

function BottomTabs() {
  const navigate = useNavigate();
  const { data: open_balance } = useQuery<boolean>({
    queryKey: ["open_balance"],
  });
  const queryClient = useQueryClient();
  return (
    <div css={BottomTabsStyle.index}>
      <div className="list-menu">
        {listMenus.map((item, index) => {
          const className = classNames({
            "menu-item": true,
            "balance-active":
              item.path === PATH.HomeMobile + "/balance" && open_balance,
          });
          return (
            <div
              onClick={() => {
                queryClient.setQueryData(["open_balance"], false);
                queryClient.setQueryData(["open_sidebar"], false);
                if (PATH.HomeMobile + "/balance" === item.path)
                  return queryClient.setQueryData(["open_balance"], true);
                if (PATH.HomeMobile + "/menu" === item.path)
                  return queryClient.setQueryData(["open_sidebar"], true);
                if (item.path) return navigate(item.path);
              }}
              key={index}
              className={className}
            >
              {item.icon}
              <span>{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BottomTabs;
