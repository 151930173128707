import { css } from "@emotion/react";

export const ModalBetATMStyle = {
  form: css`
    position: relative;
    padding: 15px;
    .input-group {
      width: 100%;
      position: relative;
      display: flex;
      border-collapse: separate;
      align-items: center;

      .input-group-addon {
        background-color: #f5f5f5;
        border: rgba(0, 0, 0, 0.12) 1px solid;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        white-space: nowrap;
        vertical-align: middle;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        color: #555;
        text-align: center;
        border-collapse: separate;
      }

      input {
        height: 34px;
        padding: 0px 12px;
        width: 100%;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        display: table-cell;
        outline: none;
        transition:
          border-color ease-in-out 0.15s,
          box-shadow ease-in-out 0.15s;
        margin-bottom: 0;
        z-index: auto;
      }
    }
  `,
};
