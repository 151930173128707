/** @jsxImportSource @emotion/react */

import { ModalWrapperStyle } from "./style";

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

function ModalWrapper({ open, onClose, children }: Props) {
  return (
    <div css={ModalWrapperStyle.index(open)}>
      <div className="content-modal">{children}</div>
      <div onClick={onClose} className="modal-backdrop" />
    </div>
  );
}

export default ModalWrapper;
