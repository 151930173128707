/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button } from "antd";

interface ButtonProps {
  title: string;
  onPress: () => void;
  background: string;
  color: string;
  loading?: boolean;
}

type Props = {
  data: ButtonProps[];
  paddingBottom?: number;
};

function GroupButton({ data, paddingBottom }: Props) {
  return (
    <div
      css={css`
        padding: 15px 15px 1px 15px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        margin-top: -1px;
        height: 40px;
        display: flex;
        gap: 0 5px;
        padding-bottom: ${paddingBottom ? paddingBottom + "px" : "1px"};
      `}
    >
      {data.map((item, index) => {
        return (
          <Button
            key={index}
            onClick={item.onPress}
            loading={item.loading || false}
            css={css`
              width: 50%;
              background-color: ${item.background};
              color: ${item.color};
              display: flex;
              text-align: center;
              justify-content: center;
              border-radius: 2px;
              border-width: 0;
              padding: 10px 12px;
              height: 100%;
            `}
          >
            <span>{item.title}</span>
          </Button>
        );
      })}
    </div>
  );
}

export default GroupButton;
