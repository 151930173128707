/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

type Props = {
  title: string;
};

function ModalHeader({ title }: Props) {
  return (
    <div
      css={css`
        color: #fff;
        background: radial-gradient(
          ellipse at center,
          #5ecd5e 0%,
          #137433 100%
        );
        min-height: 16.43px;
        padding: 15px;
        border-bottom: 1px solid #e5e5e5;
      `}
    >
      {title}
    </div>
  );
}

export default ModalHeader;
