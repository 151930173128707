import { css } from "@emotion/react";

export const HeaderPageStyle = {
  index: (position: string) => css`
    background-color: #587fce;
    color: #fff;
    height: 40px;
    line-height: 34px;
    font-size: 20px;
    font-weight: bold;
    padding: 0 8px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    i {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }

    span {
      flex: 1;
      text-align: ${position};
      font-size: 14px;
      line-height: 34px;
    }
  `,
};
