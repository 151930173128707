import { css } from "@emotion/react";

export const DefaultLayoutStyle = {
  wrapper: css``,

  layout: css``,

  menu: css``,

  content: css``,
};
