/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import MenuGame from "components/MenuGame";
import Loading from "components/common/Loading";
import { DefaultLayoutStyle } from "./style.index";
import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { PATH } from "constants/path";
import Header from "components/Header";
import { USERNAME } from "constants/user";

export const DefaultLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isLogin = localStorage.getItem(USERNAME);
    if (!isLogin) return navigate(PATH.Login);
  }, [navigate]);

  return (
    <div css={DefaultLayoutStyle.wrapper}>
      <main
        css={css`
          background-color: #f3f3f3;
          min-height: 100vh;
        `}
      >
        <Suspense fallback={<Loading />}>
          <Header />
          <MenuGame />
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};
