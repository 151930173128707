import "./i18n";

import { ThemeProvider } from "@emotion/react";
import Loading from "components/common/Loading";
import { PATH } from "constants/path";
import { isMobile } from "helpers/isMobile";
import { useIP } from "hooks/useIP";
import { useLoading } from "hooks/useLoading";
import { Suspense, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import GlobalStyles from "styles/global";
import { theme } from "styles/theme";

function App() {
  const { open, hide } = useLoading();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useIP();
  useEffect(() => {
    open();
    setTimeout(() => {
      hide();
    }, 1000);
    if (!isMobile) return;
    const isLinkMobile = pathname.substring(0, 2) === PATH.HomeMobile;
    if (isLinkMobile) return;
    const isWeb = localStorage.getItem("device") === "web";
    if (isWeb) return localStorage.removeItem("device");
    navigate(PATH.HomeMobile + pathname);
    localStorage.removeItem("device");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [true]);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
