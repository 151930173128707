import { css } from "@emotion/react";

export const ContentInnerStyle = {
  wrapper: css`
    padding: 30px 20px 15px;
    height: 300px;
  `,

  img: css`
    text-align: center;
    img {
      width: 200px;
    }
  `,

  title: css`
    margin: 12px 14px 0 14px;
    span {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      line-height: 2em;
    }
  `,

  text: css`
    margin: 0 14px;

    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
    }
  `,
};
