import { css } from "@emotion/react";

export const MenuSideBarStyle = {
  index: (isOpen: boolean) => css`
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    width: 270px;
    height: 100vh;
    z-index: 10;
    overflow-y: auto;
    transition: all 0.15s ease-in-out;
    box-shadow: 0px -14px 14px rgba(0, 0, 0, 0.25);
    transform: translateX(${!isOpen ? "270px" : "0px"});
  `,
  modal_backdrop: (isOpen: boolean) => css`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background-color: #000;
    opacity: 0.5;
    inset: 0;
    display: ${isOpen ? "block" : "none"};
  `,
  listMenu: css`
    margin-bottom: 0;
    .main-bar {
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
      background-color: #587fce;
      color: #fff;
      height: 40px;
      line-height: 34px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      padding-left: 15px;
      .title {
        display: flex;
        font-weight: bold;
        align-items: center;
        height: 40px;
        text-transform: uppercase;
        font-size: 14px;
      }
    }
    .list {
      overflow: auto;
      margin-bottom: 0;
      padding: 4px 0;
      .item-menu {
        color: rgba(0, 0, 0, 0.87);
        border: 0;
        padding: 8px 24px 8px 16px;
        text-transform: uppercase;
        border-radius: 0;
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: 25px;
          object-fit: contain;
          margin-right: 10px;
        }

        .icon {
          color: rgba(0, 0, 0, 0.54);
          vertical-align: middle;
          margin-right: 16px;
          position: relative;
          width: 24px;
          padding-left: 3px;
          height: 28px;
          font-size: 18px;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-of-type(2) {
            margin-right: 0;
          }
        }
        span {
          flex: 1;
          font-size: 13px;
        }
      }
    }
  `,
};
