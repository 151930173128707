import { css } from "@emotion/react";

export const BetListPageStyle = {
  wrapper: css``,

  tabs: css`
    background-color: #222;
    height: 40px;

    padding: 0 8px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `,
};
