/** @jsxImportSource @emotion/react */

import React from "react";
import { ContainerStyle } from "./style.index";

const Container = ({ children }: { children: React.ReactNode }) => {
  return <div css={ContainerStyle.wrapper}>{children}</div>;
};

export default Container;
