/** @jsxImportSource @emotion/react */

import HeaderPage from "components/Mobile/HeaderPage";
import { useLocation } from "react-router-dom";
import ResultTab from "./ResultTab";
import RunningTab from "./RunningTab";
import StatementTab from "./StatementTab";
import TabTitle from "./TabTitle";
import { BetListPageStyle } from "./style.index";

const LIST_TABS = [
  {
    name: "Running",
    href: "running",
    component: <RunningTab />,
    key: 0,
  },
  {
    name: "Statement",
    href: "statement",
    component: <StatementTab />,
    key: 1,
  },
  {
    name: "Result",
    href: "result",
    component: <ResultTab />,
    key: 2,
  },
];

const BetListPage = () => {
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);
  const tabParam = urlSearchParams.get("tab");

  const activeTab =
    LIST_TABS.find((item) => item.href === tabParam) || LIST_TABS[0];

  return (
    <div css={BetListPageStyle.wrapper}>
      <HeaderPage title="BET LIST" position="left" />

      <div css={BetListPageStyle.tabs}>
        {LIST_TABS.map((item) => (
          <TabTitle {...item} isActive={tabParam === item.href} />
        ))}
      </div>

      {activeTab.component}
    </div>
  );
};

export default BetListPage;
