/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Loading from "components/common/Loading";
import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { PATH } from "constants/path";
import { USERNAME } from "constants/user";

export const PublicLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isLogin = localStorage.getItem(USERNAME);
    if (!isLogin) return navigate(PATH.Login);
  }, [navigate]);

  return (
    <div>
      <main css={css``}>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};
