/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

function CheckBoxCustom() {
  const [checked, setChecked] = useState(true);
  if (checked)
    return (
      <i
        onClick={() => setChecked(false)}
        className="fa-solid fa-square-check"
      ></i>
    );
  return (
    <i
      onClick={() => setChecked(true)}
      css={css`
        color: #fff !important;
      `}
      className="fa-regular fa-square-check"
    ></i>
  );
}

export default CheckBoxCustom;
