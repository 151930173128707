/** @jsxImportSource @emotion/react */

import HeaderPage from "components/Mobile/HeaderPage";
import { SettingsPageStyle } from "./style";
import { USERNAME } from "constants/user";
import SelectLanguage from "components/SelectLanguage";
import { listChips, listFavorite } from "./list";
import ModalChangePassword from "pages/mobile/SettingsPage/ModalChangePassword";
import { queryClient } from "index";
import ModalBetATM from "./ModalBetATM";
import CheckBoxCustom from "./CheckBoxCustom";
import { css } from "@emotion/react";
import { useState } from "react";

function SettingsPage() {
  const [chips, setChips] = useState<string[]>([]);
  return (
    <div css={SettingsPageStyle.index}>
      <HeaderPage title="SETTING" />
      <div css={SettingsPageStyle.list}>
        <ModalChangePassword />
        <ModalBetATM />
        <div className="item-row">
          <span>User Name</span>
          <span>@@@{localStorage.getItem(USERNAME)}###</span>
        </div>
        <div
          onClick={() => {
            queryClient.setQueryData(["open_change_password"], true);
          }}
          className="item-row"
        >
          <span>Password</span>
          <span>******</span>
        </div>
        <div className="item-row">
          <span>Language</span>
          <span>
            <SelectLanguage disable />
          </span>
        </div>
      </div>
      <div css={SettingsPageStyle.list}>
        <div className="item-row">
          <span>Odds Type</span>
          <span>
            <select>
              <option value={"HK"}>HK</option>
              <option value={"MY"}>MY</option>
              <option value={"EU"}>EU</option>
            </select>
          </span>
        </div>
      </div>
      <div css={SettingsPageStyle.list}>
        <label className="item-row">
          <span>ACCEPT BETTER ODDS</span>
          <span>
            <i className="fa-solid fa-square-check"></i>
          </span>
        </label>
      </div>
      <div css={SettingsPageStyle.list}>
        <div
          onClick={() => queryClient.setQueryData(["open_bet_atm"], true)}
          className="item-row"
        >
          <span>QUICK SINGLE BET AMT</span>
          <span>0</span>
        </div>
        <div
          onClick={() => queryClient.setQueryData(["open_bet_atm"], true)}
          className="item-row"
        >
          <span>QUICK PAR BET AMT</span>
          <span>0</span>
        </div>
        <div
          onClick={() => queryClient.setQueryData(["open_bet_atm"], true)}
          className="item-row"
        >
          <span>QUICK PAR SINGLE BET AMT</span>
          <span>0</span>
        </div>
      </div>
      <div css={SettingsPageStyle.list}>
        <label className="item-row">
          <span>AutoRefresh</span>
          <span>
            <CheckBoxCustom />
          </span>
        </label>
        <div className="item-row">
          <span>Default Sorting</span>
          <span>
            <select>
              <option>Normal Sorting</option>
              <option>Sort by Time</option>
            </select>
          </span>
        </div>
        <div className="item-row">
          <span>Market Type</span>
          <span>
            <select defaultValue={2}>
              <option value={1}>All</option>
              <option value={2}>Main</option>
              <option value={3}>Other</option>
            </select>
          </span>
        </div>
        <div className="item-row">
          <span>Score Sound</span>
          <span>
            <select
              css={css`
                text-transform: uppercase;
              `}
              defaultValue={0}
            >
              <option value={1}>sound 1</option>
              <option value={2}>sound 2</option>
              <option value={3}>sound 3</option>
              <option value={4}>sound 4</option>
              <option value={5}>sound 5</option>
              <option value={6}>sound 6</option>
              <option value={7}>sound 7</option>
              <option value={0}>Disable Sound</option>
            </select>
          </span>
        </div>
        <div className="item-row">
          <span>BETTING CHIP</span>
          <span>
            <select
              css={css`
                text-transform: uppercase;
              `}
              defaultValue={2}
            >
              <option value={1}>ENABLE</option>
              <option value={2}>DISABLE</option>
            </select>
          </span>
        </div>
        <div className="item-row">
          <span>BET POP UP</span>
          <span>
            <select
              css={css`
                text-transform: uppercase;
              `}
              defaultValue={1}
            >
              <option value={1}>Pop Up with Live Centre</option>
              <option value={2}>Pop Up without Live Centre</option>
            </select>
          </span>
        </div>
        <div className="item-row">
          <span>Chip Set</span>
          <div className="list-chips">
            {listChips.slice(7, listChips.length).map((item, index) => {
              const isActive = chips.includes(item);
              return (
                <img
                  onClick={() => {
                    if (isActive) {
                      setChips(chips.filter((i) => i !== item));
                    } else {
                      setChips([...chips, item]);
                    }
                  }}
                  className={isActive ? "active" : ""}
                  src={item}
                  key={index}
                  alt=""
                />
              );
            })}
          </div>
        </div>
        <div className="item-row">
          <span></span>
          <div className="list-chips">
            {listChips.slice(0, 7).map((item, index) => {
              const isActive = chips.includes(item);
              return (
                <img
                  onClick={() => {
                    if (isActive) {
                      setChips(chips.filter((i) => i !== item));
                    } else {
                      setChips([...chips, item]);
                    }
                  }}
                  className={isActive ? "active" : ""}
                  src={item}
                  key={index}
                  alt=""
                />
              );
            })}
          </div>
        </div>
        <div className="item-row">
          <span>MY FAVORITE</span>
          <span />
        </div>
        <div className="item-row">
          <span></span>
          <div className="check-box">
            {listFavorite.slice(0, 6).map((item, index) => {
              return (
                <div key={index} className="item-check-box">
                  <input
                    id={`${item}+checkbox`}
                    className="icon icon-checkbox"
                    type="checkbox"
                    defaultChecked
                  />
                  <label htmlFor={`${item}+checkbox`} className="title">
                    <span>{item}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="item-row">
          <span></span>
          <div className="check-box">
            {listFavorite.slice(6, 12).map((item, index) => {
              return (
                <div key={index} className="item-check-box">
                  <input
                    id={`${item}+checkbox`}
                    className="icon icon-checkbox"
                    type="checkbox"
                    defaultChecked
                  />
                  <label htmlFor={`${item}+checkbox`} className="title">
                    <span>{item}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="item-row">
          <span></span>
          <div className="check-box">
            {listFavorite.slice(12, 18).map((item, index) => {
              return (
                <div key={index} className="item-check-box">
                  <input
                    id={`${item}+checkbox`}
                    className="icon icon-checkbox"
                    type="checkbox"
                    defaultChecked
                  />
                  <label htmlFor={`${item}+checkbox`} className="title">
                    <span>{item}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="item-row">
          <span></span>
          <div className="check-box">
            {listFavorite.slice(18, 23).map((item, index) => {
              return (
                <div key={index} className="item-check-box">
                  <input
                    id={`${item}+checkbox`}
                    className="icon icon-checkbox"
                    type="checkbox"
                    defaultChecked
                  />
                  <label htmlFor={`${item}+checkbox`} className="title">
                    <span>{item}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="item-row">
          <span></span>
          <div style={{ justifyContent: "flex-end" }} className="check-box">
            {listFavorite.slice(23, 26).map((item, index) => {
              return (
                <div key={index} className="item-check-box">
                  <input
                    id={`${item}+checkbox`}
                    className="icon icon-checkbox"
                    type="checkbox"
                    defaultChecked
                  />
                  <label htmlFor={`${item}+checkbox`} className="title">
                    <span>{item}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
