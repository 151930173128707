import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { payloadHelper } from "helpers/payload-helper";

import {
  AnnouncementFormType,
  AnnouncementResponseType,
  MemberMessageFormType,
  MemberMessageResponseType,
} from "./type";

export const AnnouncementApi = {
  getAnnouncement: async (payload: AnnouncementFormType) => {
    const res = await axiosRequest.post<AnnouncementResponseType>("/", payloadHelper("Announcement", null, payload));
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },
  getMemberMessage: async (payload: MemberMessageFormType) => {
    const res = await axiosRequest.post<MemberMessageResponseType>(
      "/",
      payloadHelper("MemberMessage", String(payload.username), payload),
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },
};
