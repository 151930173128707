import { css } from "@emotion/react";

export const ModalChangePasswordStyle = {
  form: css`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;

    input {
      display: table-cell;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      height: 34px;
      padding: 0 12px;
    }
    .text-danger {
      color: red;
      font-size: 12px;
      padding: 0 8px;
    }
  `,
};
