/** @jsxImportSource @emotion/react */

import handleLoginGame from "helpers/handleLoginGame";
import { CardGameStyle } from "./style.index";
import { SubGameType } from "types/game";

interface Props {
  picture: string;
  name: string;
  username: string;
  dataLogin: SubGameType;
}

const CardGame = (props: Props) => {
  const { name, picture, username, dataLogin } = props;
  return (
    <div css={CardGameStyle.wrapper}>
      <div css={CardGameStyle.img}>
        <img className="image-card" src={picture} alt="" />

        <div className="hot">
          <img
            src="https://d2dgys6tg64hrs.cloudfront.net/public/pragmati/hot.gif"
            alt=""
          />
        </div>
        <div className="overflow">
          <div
            onClick={() => handleLoginGame(dataLogin, username)}
            css={CardGameStyle.btn}
          >
            Play Free
          </div>
          <div
            onClick={() => handleLoginGame(dataLogin, username)}
            css={CardGameStyle.btn}
          >
            Play Now
          </div>
        </div>
      </div>
      <span>{name}</span>
    </div>
  );
};

export default CardGame;
