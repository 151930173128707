import { css } from "@emotion/react";

export const PopupChangeNameStyle = {
  wrapper: (isOpen: boolean) => css`
    display: ${isOpen ? "block" : "none"};
    z-index: 10;
    position: absolute;
    top: 40px;

    p {
      padding: 0px 20px 8px 20px;
      span {
        color: red;
      }

      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
    }
  `,

  content: css`
    background-color: white;
    margin: 10px;

    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  `,

  header: css`
    background: radial-gradient(ellipse at center, #5ecd5e 0%, #137433 100%);
    min-height: 16.43px;
    border-bottom: 1px solid #e5e5e5;
  `,

  form: css`
    border-bottom: 1px solid #e5e5e5;
    max-height: calc(100vh - 190px);
    overflow: auto;
  `,

  input: css`
    padding: 8px 12px 0;
    margin-bottom: 15px;

    input {
      display: block;
      width: 100%;
      height: 34px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  `,

  errors: css`
    color: red;
    font-size: 12px;
    padding-left: 20px;
  `,

  btn: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 15px;

    border-top: 1px solid #e5e5e5;

    button {
      height: 40px;
      border-radius: unset;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      color: white;
      border: unset;
      background-color: #31c24f;
      &:first-of-type {
        background-color: orange;
      }
    }
  `,
};
