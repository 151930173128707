import { languages } from "constants/languages";
import { TOKEN, USERNAME } from "constants/user";
import i18n from "i18n";
import { queryClient } from "index";

export const handleLogout = () => {
  queryClient.clear();
  localStorage.removeItem(USERNAME);
  localStorage.removeItem(TOKEN);
  onChangeLanguage(languages[0].code);
  window.location.reload();
};

const onChangeLanguage = (value: string) => {
  localStorage.setItem("i18nLng", value);
  i18n.changeLanguage(value);
  queryClient.refetchQueries();
  const element = document.getElementsByTagName("html");
  if (element) {
    element[0].lang = value;
  }
  // window.location.reload();
};
