import { css } from "@emotion/react";

export const MyAccountPageStyle = {
  wrapper: css`
    position: relative;

    padding-bottom: 100px;
    overflow-y: scroll;
  `,

  group: css`
    margin: 8px !important;
    box-shadow: 0px 1px 1.5px rgba(0, 0, 0, 0.12);
    margin-bottom: 0;
  `,

  field: css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid #ddd;
    padding: 12px !important;
    background-color: rgba(244, 236, 188, 0.3);
  `,

  title: css`
    text-transform: uppercase;
    color: black;
  `,
  value: css`
    color: #f353e5;
    text-transform: uppercase;
  `,

  mark: (isShow: boolean) => css`
    display: ${isShow ? "block" : "none"};
    z-index: 9;
    position: absolute;
    top: 40px;
    left: 0;

    height: 100vh;
    width: 100%;
    background-color: black;
    opacity: 0.5;
  `,
};
