import { css } from "@emotion/react";

export const SportGameStyle = {
  wrapper: css``,

  loading: css`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
  `,

  content: css`
    overflow: scroll;
    height: 100vh;
  `,
};
