/** @jsxImportSource @emotion/react */

import React from "react";
import { ResultTabStyle } from "../style.index";
import NormalResult from "./NormalResult";
import StatementTab from "../../StatementTab";

interface TypeMatch {
  time: string;
  match_1: string;
  match_2: string;
}

interface TypeListLeague {
  title: string;
  content: Array<TypeMatch>;
}
const LIST_LEAGUE: TypeListLeague[] = [
  {
    title: "AFC ASIAN CUP 2023 [ IN QATAR ]",
    content: [
      {
        time: "06/02/2024 11:00PM",
        match_1: "Jordan",
        match_2: "Korea Republic",
      },
    ],
  },
  {
    title: "AFC ASIAN CUP 2023 [ IN QATAR ]",
    content: [
      {
        time: "06/02/2024 11:00PM",
        match_1: "Jordan",
        match_2: "Korea Republic",
      },
    ],
  },
];

const Normal = () => {
  return (
    <React.Fragment>
      <div css={ResultTabStyle.selectGroup}>
        <select defaultValue={"today"} name="" id="">
          <option value="today">TODAY</option>
          <option value="yesterday">YESTERDAY</option>
          <option value="04/02">04/02</option>
          <option value="03/02">03/02</option>
          <option value="02/02">02/02</option>
          <option value="01/02">01/02</option>
          <option value="31/01">31/01</option>
          <option value="30/01">30/01</option>
        </select>
        <select defaultValue={"Soccer"} name="" id="">
          <option>Soccer</option>

          <option>1D</option>

          <option>2D</option>

          <option>Basketball</option>

          <option>Olympic</option>

          <option>US Football</option>

          <option>Baseball</option>

          <option>Ice Hockey</option>

          <option>Pool/Snooker</option>

          <option>Rugby</option>

          <option>Tennis</option>

          <option>Darts</option>

          <option>BOXING</option>

          <option>Formula 1</option>

          <option>Motor Sports</option>

          <option>Golf</option>

          <option>Futsal</option>

          <option>Badminton</option>

          <option>Water Polo</option>

          <option>Table Tennis</option>

          <option>Cricket</option>

          <option>Volleyball</option>

          <option>Handball</option>

          <option>Beach Soccer</option>

          <option>Financials</option>

          <option>Winter Sport</option>

          <option>Cycling</option>

          <option>Squash</option>

          <option>Huay Thai</option>

          <option>Athletics</option>

          <option>E-SPORTS </option>

          <option>Muay Thai</option>
        </select>
      </div>

      <select css={ResultTabStyle.selectHeading}>
        <option>All League</option>
        <option>AFC ASIAN CUP 2023 [ IN QATAR ]</option>
        <option>AFC ASIAN CUP 2023 [ IN QATAR ] - 15 MIN HDP &amp; OU</option>
        <option>
          AFC ASIAN CUP 2023 [ IN QATAR ] - 15 MIN NUMBER OF CORNERS
        </option>
        <option>AFC ASIAN CUP 2023 [ IN QATAR ] - BOOKING</option>
        <option>AFC ASIAN CUP 2023 [ IN QATAR ] - CORNERS</option>
        <option>AFC ASIAN CUP 2023 [ IN QATAR ] - TEAM TOTAL GOALS</option>
        <option>
          AFC ASIAN CUP 2023 [ IN QATAR ] - WHICH TEAM WILL ADVANCE TO NEXT
          ROUND
        </option>
        <option>ARGENTINA COPA LIGA PROFESIONAL</option>
        <option>ARGENTINA COPA LIGA PROFESIONAL - 15 MIN HDP &amp; OU</option>
        <option>ARGENTINA COPA LIGA PROFESIONAL - TEAM TOTAL GOALS</option>
        <option>ARGENTINA CUP</option>
        <option>ATLANTIC CUP [ IN PORTUGAL ]</option>
        <option>BAHRAIN PREMIER LEAGUE</option>
        <option>BAHRAIN PREMIER LEAGUE - TEAM TOTAL GOALS</option>
        <option>BANGLADESH FEDERATION CUP</option>
        <option>CLUB FRIENDLY</option>
        <option>CLUB FRIENDLY - TEAM TOTAL GOALS</option>
        <option>COLOMBIA PRIMERA A</option>
        <option>COLOMBIA PRIMERA A - 15 MIN HDP &amp; OU</option>
        <option>COLOMBIA PRIMERA A - TEAM TOTAL GOALS</option>
        <option>CONCACAF CHAMPIONS CUP</option>
        <option>CONCACAF CHAMPIONS CUP - 15 MIN HDP &amp; OU</option>
        <option>CONCACAF CHAMPIONS CUP - TEAM TOTAL GOALS</option>
        <option>COPA LIBERTADORES QUALIFIERS</option>
        <option>COPA LIBERTADORES QUALIFIERS - 15 MIN HDP &amp; OU</option>
        <option>COPA LIBERTADORES QUALIFIERS - TEAM TOTAL GOALS</option>
        <option>CYPRUS 1ST DIV</option>
        <option>CYPRUS 1ST DIV - TEAM TOTAL GOALS</option>
        <option>E-FOOTBALL F23 ELITE CLUB FRIENDLY [ 2 X 8 MINS ]</option>
        <option>E-FOOTBALL F23 INTERNATIONAL FRIENDLY [ 2 X 8 MINS ]</option>
        <option>EGYPT LEAGUE CUP</option>
        <option>EGYPT LEAGUE CUP - 15 MIN HDP &amp; OU</option>
        <option>EGYPT LEAGUE CUP - TEAM TOTAL GOALS</option>
        <option>ENGLISH FA CUP</option>
        <option>ENGLISH FA CUP - 15 MIN HDP &amp; OU</option>
        <option>ENGLISH FA CUP - CORNERS</option>
        <option>ENGLISH FA CUP - TEAM TOTAL GOALS</option>
        <option>ENGLISH FA CUP - WHICH TEAM WILL ADVANCE TO NEXT ROUND</option>
        <option>ENGLISH LEAGUE ONE</option>
        <option>ENGLISH LEAGUE ONE - 15 MIN HDP &amp; OU</option>
        <option>ENGLISH LEAGUE ONE - TEAM TOTAL GOALS</option>
        <option>ENGLISH LEAGUE TWO</option>
        <option>ENGLISH LEAGUE TWO - TEAM TOTAL GOALS</option>
        <option>ENGLISH NATIONAL LEAGUE</option>
        <option>ENGLISH NATIONAL LEAGUE - TEAM TOTAL GOALS</option>
        <option>ENGLISH NATIONAL LEAGUE NORTH</option>
        <option>ENGLISH NATIONAL LEAGUE NORTH - TEAM TOTAL GOALS</option>
        <option>ENGLISH NATIONAL LEAGUE SOUTH</option>
        <option>ENGLISH NATIONAL LEAGUE SOUTH - TEAM TOTAL GOALS</option>
        <option>ENGLISH SOUTHERN PREMIER DIVISION CENTRAL</option>
        <option>FANTASY MATCH</option>
        <option>FRANCE CUP</option>
        <option>FRANCE CUP - 15 MIN HDP &amp; OU</option>
        <option>FRANCE CUP - TEAM TOTAL GOALS</option>
        <option>FRANCE CUP - WHICH TEAM WILL ADVANCE TO NEXT ROUND</option>
        <option>GERMANY 3RD LEAGUE</option>
        <option>GERMANY 3RD LEAGUE - 15 MIN HDP &amp; OU</option>
        <option>GERMANY 3RD LEAGUE - TEAM TOTAL GOALS</option>
        <option>GERMANY CUP</option>
        <option>GERMANY CUP - 15 MIN HDP &amp; OU</option>
        <option>GERMANY CUP - CORNERS</option>
        <option>GERMANY CUP - TEAM TOTAL GOALS</option>
        <option>GERMANY CUP - WHICH TEAM WILL ADVANCE TO NEXT ROUND</option>
        <option>GERMANY REGIONAL LEAGUE NORTHEAST</option>
        <option>GERMANY REGIONAL LEAGUE NORTHEAST - TEAM TOTAL GOALS</option>
        <option>GERMANY REGIONAL LEAGUE WEST</option>
        <option>GERMANY REGIONAL LEAGUE WEST - TEAM TOTAL GOALS</option>
        <option>HUNGARY OTP BANK LIGA NB 1</option>
        <option>HUNGARY OTP BANK LIGA NB 1 - TEAM TOTAL GOALS</option>
        <option>INDONESIA LIGA 1</option>
        <option>INDONESIA LIGA 1 - TEAM TOTAL GOALS</option>
        <option>ISRAEL LIGA ALEF</option>
        <option>ISRAEL LIGA ALEF - TEAM TOTAL GOALS</option>
        <option>ISRAEL LIGA BET</option>
        <option>ISRAEL LIGA BET - TEAM TOTAL GOALS</option>
        <option>ITALY SERIE C</option>
        <option>ITALY SERIE C - TEAM TOTAL GOALS</option>
        <option>ITALY WOMEN CUP</option>
        <option>ITALY WOMEN CUP - TEAM TOTAL GOALS</option>
        <option>MEXICO LIGA DE EXPANSION</option>
        <option>MEXICO LIGA DE EXPANSION - 15 MIN HDP &amp; OU</option>
        <option>MEXICO LIGA DE EXPANSION - TEAM TOTAL GOALS</option>
        <option>NETHERLANDS CUP</option>
        <option>NETHERLANDS CUP - 15 MIN HDP &amp; OU</option>
        <option>NETHERLANDS CUP - TEAM TOTAL GOALS</option>
        <option>NETHERLANDS CUP - WHICH TEAM WILL ADVANCE TO NEXT ROUND</option>
        <option>NORTHERN IRELAND PREMIERSHIP</option>
        <option>NORTHERN IRELAND PREMIERSHIP - TEAM TOTAL GOALS</option>
        <option>PORTUGAL LIGA REVELACAO U23 PLAYOFF</option>
        <option>PORTUGAL LIGA REVELACAO U23 PLAYOFF - TEAM TOTAL GOALS</option>
        <option>SABA CLUB FRIENDLY VIRTUAL PES 21 - 15 MINS PLAY</option>
        <option>
          SABA INTERNATIONAL FRIENDLY VIRTUAL PES 21 - 20 MINS PLAY
        </option>
        <option>SAFF WOMEN CHAMPIONSHIP U19 [ IN BANGLADESH ]</option>
        <option>
          SAFF WOMEN CHAMPIONSHIP U19 [ IN BANGLADESH ] - TEAM TOTAL GOALS{" "}
        </option>
        <option>SAUDI ARABIA 1ST DIV</option>
        <option>SAUDI ARABIA 1ST DIV - TEAM TOTAL GOALS</option>
        <option>SCOTLAND LEAGUE 1</option>
        <option>SCOTLAND LEAGUE 1 - TEAM TOTAL GOALS</option>
        <option>SCOTLAND LEAGUE 2</option>
        <option>SCOTLAND LEAGUE 2 - TEAM TOTAL GOALS</option>
        <option>SCOTLAND PREMIERSHIP</option>
        <option>SCOTLAND PREMIERSHIP - 15 MIN HDP &amp; OU</option>
        <option>SCOTLAND PREMIERSHIP - TEAM TOTAL GOALS</option>
        <option>SPAIN CUP</option>
        <option>SPAIN CUP - 15 MIN HDP &amp; OU</option>
        <option>SPAIN CUP - CORNERS</option>
        <option>SPAIN CUP - TEAM TOTAL GOALS</option>
        <option>SPAIN CUP - WHICH TEAM WILL ADVANCE TO NEXT ROUND</option>
        <option>SWISS CHALLENGE LEAGUE</option>
        <option>SWISS CHALLENGE LEAGUE - TEAM TOTAL GOALS</option>
        <option>TUNISIA PROFESSIONAL LIGUE 1 PLAYOFF</option>
        <option>TUNISIA PROFESSIONAL LIGUE 1 PLAYOFF - TEAM TOTAL GOALS</option>
        <option>TURKIYE CUP</option>
        <option>TURKIYE CUP - 15 MIN HDP &amp; OU</option>
        <option>TURKIYE CUP - TEAM TOTAL GOALS</option>
        <option>TURKIYE CUP - WHICH TEAM WILL ADVANCE TO NEXT ROUND</option>
        <option>UEFA YOUTH LEAGUE PLAYOFF</option>
        <option>
          UEFA YOUTH LEAGUE PLAYOFF - WHICH TEAM WILL ADVANCE TO NEXT ROUND{" "}
        </option>
        <option>URUGUAY CUP</option>
        <option>WALES PREMIER LEAGUE PLAYOFF</option>
      </select>

      <div css={ResultTabStyle.listResult}>
        {LIST_LEAGUE.map((item, index) => (
          <NormalResult key={index} {...item} />
        ))}
      </div>

      <StatementTab />
    </React.Fragment>
  );
};

export default Normal;
