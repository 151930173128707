/** @jsxImportSource @emotion/react */

import { IconArrowDown } from "assets/images/modals";
import { NormalResultStyle } from "./style.index";
import { useState } from "react";

interface TypeMatch {
  time: string;
  match_1: string;
  match_2: string;
}

interface Props {
  title: string;
  content: Array<TypeMatch>;
}

const NormalResult = ({ content, title }: Props) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  return (
    <div css={NormalResultStyle.wrapper}>
      <div
        className="heading"
        onClick={() => setIsShow(!isShow)}
        css={NormalResultStyle.heading(isShow)}
      >
        <span>{title}</span>
        <img src={IconArrowDown} alt="" />
      </div>
      {content.map((item, index) => (
        <div key={index} css={NormalResultStyle.content(isShow)}>
          <div css={NormalResultStyle.title.index}>
            <div css={NormalResultStyle.title.time}>{item.time}</div>
            <div css={NormalResultStyle.title.detail}>
              <span>FH</span>
              <span>FT</span>
            </div>
          </div>
          <div css={NormalResultStyle.match}>{item.match_1}</div>
          <div css={NormalResultStyle.match}>{item.match_2}</div>
        </div>
      ))}
    </div>
  );
};

export default NormalResult;
