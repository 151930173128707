/** @jsxImportSource @emotion/react */

import HeaderPage from "components/Mobile/HeaderPage";
import { MyAccountPageStyle } from "./style.index";
import { USERNAME } from "constants/user";
import { useQuery } from "@tanstack/react-query";
import { UserApi } from "api/user";
import { message } from "antd";
import { useState } from "react";
import PopupChangeName from "./PopupChangeName";

const MyAccountPage = () => {
  const username = localStorage.getItem(USERNAME) || "";

  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const { data: user } = useQuery({
    queryFn: async () => {
      const res = await UserApi.getProfile({
        username,
      });
      return res.data;
    },
    queryKey: ["PROFILE_USER"],
    onError: (err) => {
      message.error(err + "");
      return err;
    },
  });

  return (
    <div css={MyAccountPageStyle.wrapper}>
      <HeaderPage title="MY ACCOUNT" position="left" />

      <div css={MyAccountPageStyle.group}>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>User name</div>
          <div css={MyAccountPageStyle.value}>{user?.username}</div>
        </div>
        <div
          onClick={() => setIsShowPopup(true)}
          css={MyAccountPageStyle.field}
        >
          <div css={MyAccountPageStyle.title}>Nickname</div>
          <div css={MyAccountPageStyle.value}>
            {user?.nickname || user?.username}
          </div>
        </div>
      </div>

      <div css={MyAccountPageStyle.group}>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>Currency</div>
          <div css={MyAccountPageStyle.value}>USD</div>
        </div>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>Cash balance</div>
          <div css={MyAccountPageStyle.value}>3,736</div>
        </div>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>outstanding</div>
          <div css={MyAccountPageStyle.value}>0</div>
        </div>

        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>Min bet</div>
          <div css={MyAccountPageStyle.value}>1</div>
        </div>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>max per bet</div>
          <div css={MyAccountPageStyle.value}>3,000</div>
        </div>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>max per match</div>
          <div css={MyAccountPageStyle.value}>3,000</div>
        </div>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>bet credit :</div>
          <div css={MyAccountPageStyle.value}>3,736</div>
        </div>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>given credit</div>
          <div css={MyAccountPageStyle.value}>0</div>
        </div>
      </div>

      <div css={MyAccountPageStyle.group}>
        <div css={MyAccountPageStyle.field}>
          <div css={MyAccountPageStyle.title}>last login date</div>
          <div css={MyAccountPageStyle.value}>2024-02-07 10:13:08</div>
        </div>
      </div>

      <div css={MyAccountPageStyle.mark(isShowPopup)} />

      <PopupChangeName
        isOpen={isShowPopup}
        isClose={() => setIsShowPopup(false)}
      />
    </div>
  );
};

export default MyAccountPage;
