import { css } from "@emotion/react";

export const ModalWrapperStyle = {
  index: (open: boolean) => css`
    position: fixed;
    inset: 0;
    z-index: 9;
    display: ${open ? "flex" : "none"};
    align-items: flex-start;
    justify-content: center;

    .content-modal {
      background-color: #fff;
      width: 95vw;
      z-index: 10;
      margin: 10vh 0;
      min-height: 100px;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .modal-backdrop {
      top: 0;
      right: 0;
      left: 0;
      background-color: #000;
      opacity: 0.5;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9;
    }
  `,
};
