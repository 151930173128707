import { languages } from "constants/languages";
import { startTransition, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";


const languageDefault = localStorage.getItem("i18nLng");
const DEFAULT = languages[0].code;
export const useIP = () => {
  const { i18n } = useTranslation(["common", "auth","message"]);
  
  const setLocalStorage = (value: string) => {
    localStorage.setItem("i18nLng", value);
    const element = document.getElementsByTagName("html");
    if (element) {
      element[0].lang = value;
    }
  };
  const handleLanguage = useCallback(async () => {
    if (languageDefault) {
      setLocalStorage(languageDefault);
      return;
    }
    i18n.changeLanguage(DEFAULT);
    setLocalStorage(DEFAULT);
  }, [i18n]);

  useEffect(() => {
    startTransition(() => {
      handleLanguage();
    });
  }, [handleLanguage]);
};
