/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { RunningTabStyle } from "./style.index";

const LIST_BTN = [
  {
    name: "Waiting",
    key: "wait",
  },
  {
    name: "Accepted",
    key: "acp",
  },
  {
    name: "Rejected",
    key: "reject",
  },
  {
    name: "Cancelled",
    key: "cancel",
  },
];

const RunningTab = () => {
  const [isTab, setIsTab] = useState<string>("");

  return (
    <div css={RunningTabStyle.wrapper}>
      {LIST_BTN.map((item) => (
        <div
          onClick={() => setIsTab(item.key)}
          key={item.key}
          css={RunningTabStyle.btn(isTab === item.key)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default RunningTab;
