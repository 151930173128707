import { css } from "@emotion/react";

export const TabTitleStyle = {
  wrapper: css``,

  item: (isActive: boolean) => css`
    line-height: 15px;
    color: ${isActive ? "#fff" : "rgba(255, 255, 255, 0.7)"};
    text-transform: uppercase;

    height: 30px;
    text-align: center;
    background-color: ${isActive ? "#444444" : ""};
    padding-top: 8px;
  `,
};
