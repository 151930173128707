/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import ModalWrapper from "components/Mobile/ModalWrapper";
import GroupButton from "components/Mobile/ModalWrapper/GroupButton";
import ModalHeader from "components/Mobile/ModalWrapper/ModalHeader";
import { queryClient } from "index";
import { ModalBetATMStyle } from "./style";

function ModalBetATM() {
  const { data: isOpen } = useQuery<boolean>({
    queryKey: ["open_bet_atm"],
    initialData: false,
  });
  return (
    <ModalWrapper
      open={isOpen}
      onClose={() => queryClient.setQueryData(["open_change_password"], false)}
    >
      <ModalHeader title="QUICK SINGLE BET AMT" />
      <div css={ModalBetATMStyle.form}>
        <div className="input-group">
          <span className="input-group-addon">+</span>
          <input min={0} type="number" defaultValue={0} />
        </div>
      </div>
      <GroupButton
        data={[
          {
            title: "Back",
            onPress: () => {
              queryClient.setQueryData(["open_bet_atm"], false);
            },
            background: "orange",
            color: "#fff",
          },
          {
            title: "OK",
            onPress: () => {
              //
            },
            background: "#31c24f",
            color: "#fff",
          },
        ]}
      />
    </ModalWrapper>
  );
}

export default ModalBetATM;
