import { css } from "@emotion/react";

export const SlotPageStyle = {
  self: css`
    background-color: black;
    height: calc(100vh - 180.7px);
    overflow-y: scroll;

    margin-top: 50px;
  `,
  wrapper: css`
    background-color: black;
    width: 1185px;
    margin: 0 auto;
  `,

  listGame: css`
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 16px;
  `,
};
