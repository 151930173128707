import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Loading from "components/common/Loading";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routesConfig from "router";

import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter(routesConfig);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary fallbackRender={() => <div style={{
      width:"100vw",
      height:"100vh",
      alignItems:"center",
      justifyContent:"center",
      display:"flex",
      position:"fixed",
      zIndex:999,
      inset:0
    }}><Loading /></div>}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
