/** @jsxImportSource @emotion/react */

import CardGame from "components/CardGame";
import { SlotPageStyle } from "./style.index";
import { useQuery } from "@tanstack/react-query";
import { gameApi } from "api/game";
import { message } from "antd";
import HelmetCustom from "components/common/HelmetCustom";
import { USERNAME } from "constants/user";

const SlotPage = () => {
  const username = localStorage.getItem(USERNAME) || "";
  const { data } = useQuery({
    queryFn: async () => {
      const res = await gameApi.getSubGameList({
        provider: "",
        game_type: "SL",
        new: "",
        featured: "",
        lobby: 0,
        page: 1,
      });
      return res;
    },
    queryKey: ["SUBGAMELIST"],
    onError: (err) => {
      message.error(err + "");
      return err;
    },
  });

  return (
    <div css={SlotPageStyle.self}>
      <div css={SlotPageStyle.wrapper}>
        <HelmetCustom title="GT855" />
        <div css={SlotPageStyle.listGame}>
          {data?.data.map((item) => (
            <CardGame
              dataLogin={item}
              key={item.game_code}
              username={username}
              {...item}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SlotPage;
