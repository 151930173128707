import { message } from "antd";
import { gameApi } from "api/game";
import axios, { CancelTokenSource } from "axios";
import { ResponseCode } from "constants/response";
import i18next from "i18next";
import { SubGameType } from "types/game";
let source: CancelTokenSource;

const handleLoginGame = async (dataLogin: SubGameType, username: string) => {
  if (!username) {
    message.destroy();
    message.warning(i18next.t("message:loginGame:requiredLoginGame"));
    return;
  }
  message.destroy();
  message.loading({
    key: "login-game",
    content: i18next.t("message:loginGame:loadingLoginGame"),
    duration: 20,
  });

  if (source) {
    source.cancel(i18next.t("message:loginGame:cancelRequired"));
  }
  source = axios.CancelToken.source();

  try {
    const openBlank = window.open(
      "",
      "LoginGame",
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=1000,height=600,left=200,top=-1000`
    );
    if (!openBlank) return;

    const loaderElement = openBlank.document.createElement("div");
    const style = openBlank.document.createElement("style");

    loaderElement.className = "loader";

    style.textContent = `
    .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:rgba(0, 0, 0, 0.5);z-index:9999} 
    .loader::after{content:"";width:75px;height:75px;border:15px solid #dddddd;border-top-color:#6bb0c8;border-radius:50%;animation:loading 2s ease infinite} 
    @keyframes loading {
      from { transform: rotate(0turn)}
      to { transform:rotate(1turn)}
    }
  `;
    openBlank.document.title = "Loading ...";
    openBlank.document.body.appendChild(loaderElement);
    openBlank.document.head.appendChild(style);

    try {
      const response = await gameApi.loginGame(username, {
        username,
        game_type: dataLogin.type,
        game_code: dataLogin.game_code,
        provider: dataLogin.provider_code,
        source,
      });

      const dataLoginGame = response.data;

      if (dataLoginGame.status !== ResponseCode.SUCCESS) {
        message.destroy();
        message.error({
          key: "Game",
          content: i18next.t("message:game_maintenance"),
        });
        openBlank?.close();
        return;
      }
      message.destroy();
      message.success({
        key: "Game",
        content: i18next.t("message:loginGame:successLoginGame"),
      });
      (openBlank?.location as { href: string }).href = dataLoginGame.url;
    } catch (error) {
      if (
        (error as { message: string }).message.includes(
          "Operation canceled due to new request."
        )
      ) {
        return;
      }
      message.destroy();
      message.error({
        key: "Game",
        content: i18next.t("message:game_maintenance"),
      });
    }
  } catch (error) {
    const openBlank = window.open("", "LoginGame");
    if (!openBlank) return;
    openBlank.blur();
    openBlank.close();
    const openGameBlank = window.open(
      "",
      "LoginGame",
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=1000,height=600,left=200,top=-1000`
    );
    if (!openGameBlank) return;
    const loaderElement = openGameBlank.document.createElement("div");
    const style = openGameBlank.document.createElement("style");
    loaderElement.className = "loader";
    style.textContent = `
    .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:rgba(0, 0, 0, 0.5);z-index:9999} 
    .loader::after{content:"";width:75px;height:75px;border:15px solid #dddddd;border-top-color:#6bb0c8;border-radius:50%;animation:loading 2s ease infinite} 
    @keyframes loading {
      from { transform: rotate(0turn)}
      to { transform:rotate(1turn)}
    }
    `;
    openGameBlank.document.title = "Loading ...";
    openGameBlank.document.body.appendChild(loaderElement);
    openGameBlank.document.head.appendChild(style);

    try {
      const response = await gameApi.loginGame(username, {
        username,
        game_type: dataLogin.type,
        game_code: dataLogin.game_code,
        provider: dataLogin.provider_code,
        source,
      });

      const dataLoginGame = response.data;

      if (dataLoginGame.status !== ResponseCode.SUCCESS) {
        message.destroy();
        message.error({
          key: "Game",
          content: i18next.t("message:game_maintenance"),
        });
        openGameBlank?.close();
        return;
      }
      message.destroy();
      message.success({
        key: "Game",
        content: i18next.t("message:loginGame:successLoginGame"),
      });

      (openGameBlank?.location as { href: string }).href = dataLoginGame.url;
    } catch (error) {
      if (
        (error as { message: string }).message.includes(
          "Operation canceled due to new request."
        )
      ) {
        return;
      }
      message.destroy();
      message.error({
        key: "Game",
        content: i18next.t("message:game_maintenance"),
      });
    }
  }
};
export default handleLoginGame;
