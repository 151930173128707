export const useLoading = () => {
  const open = () => {
    const element = document.createElement("div");
    element.id = "loading_all_app";
    element.classList.add("loading-app-container");
    element.innerHTML = `
     <div class="loading"><i class="icon-loading"><span></span><span></span><span></span><span></span></i></div>
    `;
    document.getElementById("root")?.appendChild(element);
  };

  const hide = () => {
    document.getElementById("loading_all_app")?.remove();
  };
  return { hide, open };
};
