import { css } from "@emotion/react";

export const HeaderStyle = {
  wrapper: css`
    height: 85px;
    display: flex;
    align-items: center;
    max-width: 100%;
    background: linear-gradient(
      180deg,
      #dbe9fd,
      #e2edfd,
      #eaf2fe,
      #f1f6fe,
      #f8fbff,
      #ffffff
    );
  `,

  logo: css`
    margin: 0 20px;
    img {
      height: 57px;
      width: 175px;
    }
  `,

  center: {
    index: css`
      flex: 1;
    `,

    name: css`
      display: flex;
      align-items: center;
      gap: 5px;
      color: #3c5aa0;
      p {
        color: #03a378;
        text-transform: uppercase;
      }
    `,

    notify: css`
      height: 24px;
      background-color: #ffffff;
      width: 300px;
      min-width: 100%;
      overflow: hidden;
      line-height: 24px;
      font-size: 12px;
      padding: 0 10px;
      margin: 0;
      font-family: Arial, "Microsoft YaHei";
      & .item {
        margin-right: 20px;
      }
    `,
  },

  right: css`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-right: 12px;

    select {
      width: 90px;
      height: 20px;
      color: #4973bf;
      font-weight: normal;
      font-size: 11px;
      display: inline-block;
      margin-left: 3px;
      cursor: pointer;
      background-color: rgb(255, 255, 255);
      text-decoration: none;
      padding: 0px 6px;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(191, 189, 189);
      background-repeat: repeat-x;
      background-position: 0 0;
      text-transform: uppercase;
    }

    button {
      background-color: #5d98e9;
      height: 65px;
      color: #fff !important;
      width: 105px;
      border-radius: 6px;
      border: none;
      font-size: 20px;
      font-weight: bold;
      display: inline-block;
    }
  `,
};
