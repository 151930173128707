import { css } from "@emotion/react";

export const CardGameMobileStyle = {
  wrapper: (background?: string, fit?: string) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 2px;
    background-color: ${background ? background : "white"};
    img {
      height: 110px;
      width: 100%;
      object-fit: ${fit ? fit : "cover"};
    }

    span {
      padding: 2px 0;
      font-size: 14px;
      line-height: 1em;
      color: #5680cd;
      font-weight: bold;
      background-color: white;
      width: 100%;
      text-align: center;
    }
  `,
};
