import { css } from "@emotion/react";

export const MenuGameStyle = {
  self: css`
    position: relative;
    width: 100%;
  `,

  wrapper: css`
    height: 46px;
    background: #d3e4f6;

    display: grid;
    grid-template-columns: repeat(7, 125px);
  `,

  item: (isActive: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;

    font-size: 16px;
    color: ${isActive ? "white" : "#234187"};
    font-weight: bold;
    background-color: ${isActive && "#3a64b2"};
  `,
};
