/** @jsxImportSource @emotion/react */

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Fragment } from "react";
import { BalancePopupStyle } from "./style";
import { USERNAME } from "constants/user";

function BalancePopup() {
  const queryClient = useQueryClient();
  const { data: isOpen } = useQuery<boolean>({
    queryKey: ["open_balance"],
    initialData: false,
  });

  const data = [
    {
      name: "User Name",
      value: `@@@${localStorage.getItem(USERNAME)}###`.toUpperCase(),
    },
    {
      name: "Currency",
      value: ``,
    },
    {
      name: "Cash Balance",
      value: `0.00`,
    },
    {
      name: "Outstanding",
      value: `0`,
    },
    {
      name: "Min Bet",
      value: `0`,
    },
    {
      name: "Bet Credit : ",
      value: `0`,
    },
    {
      name: "Given Credit",
      value: `0`,
    },
  ];

  return (
    <Fragment>
      <div css={BalancePopupStyle.index(isOpen)}>
        {data.map((item, index) => {
          return (
            <div key={index} className="item-row">
              <span>{item.name}</span>
              <span>{item.value}</span>
            </div>
          );
        })}
      </div>
      <div
        onClick={() => queryClient.setQueryData(["open_balance"], false)}
        css={BalancePopupStyle.modal_backdrop(isOpen)}
      />
    </Fragment>
  );
}

export default BalancePopup;
