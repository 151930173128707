/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { gameApi } from "api/game";
import HelmetCustom from "components/common/HelmetCustom";
import { USERNAME } from "constants/user";
import { SlotGameStyle } from "./style.index";
import CardGame from "components/CardGame";

const SlotGame = () => {
  const username = localStorage.getItem(USERNAME) || "";
  const { data } = useQuery({
    queryFn: async () => {
      const res = await gameApi.getSubGameList({
        provider: "",
        game_type: "SL",
        new: "",
        featured: "",
        lobby: 0,
        page: 1,
      });
      return res;
    },
    queryKey: ["SUBGAMELIST"],
    onError: (err) => {
      message.error(err + "");
      return err;
    },
  });

  return (
    <div css={SlotGameStyle.wrapper}>
      <HelmetCustom title="GT855" />
      <div css={SlotGameStyle.listGame}>
        {data?.data.map((item) => (
          <CardGame
            dataLogin={item}
            key={item.game_code}
            username={username}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

export default SlotGame;
