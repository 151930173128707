import { LanguagesProps, languages } from "constants/languages";
import { Device } from "enums/device";
import { Syslang } from "enums/language";
import { find } from "lodash";
import md5 from "md5";
import { ApiPayload } from "types/api";
import { isMobile } from "./isMobile";

const agentId = process.env.REACT_APP_AGENT_ID as string;
const secretKey = process.env.REACT_APP_SECRET_KEY as string;

const hashSignature = (typeName: string | null) =>
  typeName ? md5(agentId + typeName + secretKey) : md5(agentId + secretKey);

export const payloadHelper = (
  methodName: string,
  typeName: string | null,
  data: any
) => {
  const payload: ApiPayload<any> = {
    agentId,
    signature: hashSignature(typeName),
    data,
    method: methodName,
    syslang:
      find(
        languages,
        (item: LanguagesProps) => item.code === localStorage.getItem("i18nLng")
      )?.id || Syslang.ENGLISH,
    device: isMobile ? Device.MOBILE : Device.WEB,
  };
  if (!data) {
    delete payload["data"];
  }
  return payload;
};
