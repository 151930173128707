import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
  children?: React.ReactNode;
}

function HelmetCustom({ title, children }: Props) {
  return (
    <Helmet>
      <title>{title}</title>
      {children}
    </Helmet>
  );
}

export default HelmetCustom;
