import {
  chips1,
  chips10,
  chips100,
  chips1000,
  chips10000,
  chips100000,
  chips30000,
  chips50,
  chips500,
  chips5000,
  chips50000,
  chipsmax,
  chipsmin,
} from "assets/images";

export const listChips = [
  chipsmin,
  chips1,
  chips10,
  chips50,
  chips100,
  chips500,
  chips1000,
  chips5000,
  chips10000,
  chips30000,
  chips50000,
  chips100000,
  chipsmax,
];

export const listFavorite = [
  "GD88",
  "AFB Casino",
  "LG",
  "DG",
  "WM",
  "SG",
  //
  "SV388",
  "NL",
  "SA",
  "MK",
  "LUCKY361",
  "JILI",
  //
  "AFB Slots",
  "PS",
  "PRG",
  "PG",
  "Evoplay",
  "CQ",
  //
  "JKR",
  "TTG",
  "E-SPORTS",
  "COCK FIGHT",
  "KENO",
  "LOYYERY",
  //
  "WE1POKER",
];
