import { payloadHelper } from "helpers/payload-helper";
import { LoginFormType, LoginResponse, ChangePasswordFormType } from "./type";
import axiosRequest from "api";
import { ResponseCode } from "constants/response";
import { ApiResponse } from "types/api";

export const AuthApi = {
  login: async (payload: LoginFormType) => {
    const res = await axiosRequest.post<LoginResponse>(
      "/",
      payloadHelper("MemberLogin", payload.username, payload)
    );
    if (res.data.status === ResponseCode.SUCCESS) {
      return res.data;
    }
    throw new Error(res.data.msg);
  },
  changePassword: async ( payload: ChangePasswordFormType) => {
    const response = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ChangePassword", payload.username, payload)
    );
    if (response.data.status === ResponseCode.SUCCESS) {
      return response.data;
    }
    throw new Error(response.data.msg);
  },
};
