import { PATH } from "constants/path";

export const listMenus = [
  {
    name: "My Account",
    path: PATH.HomeMobile + PATH.User.index,
    icon: <i className="icon fa-solid fa-user"></i>,
  },
  {
    name: "Messages",
    path: PATH.HomeMobile + PATH.User.messages,
    icon: <i className="icon fa-regular fa-envelope"></i>,
    rightIcon: (
      <i className="icon fa-solid fa-envelope" style={{ color: "#137D33" }}></i>
    ),
  },
  {
    name: "Statement",
    path: PATH.HomeMobile + PATH.BetList + "?tab=statement",
    icon: <i className="icon fa-regular fa-id-card"></i>,
  },
  {
    name: "Result",
    icon: <i className="icon fa-solid fa-id-card-clip"></i>,
    path: PATH.HomeMobile + PATH.BetList + "?tab=result",
  },
  {
    name: "setting",
    path: PATH.HomeMobile + PATH.User.setting,
    icon: <i className="icon fa-solid fa-gear"></i>,
  },
  {
    name: "How to Use",
    path: "to-use",
    icon: <i className="icon fa-solid fa-gear"></i>,
  },
  {
    name: "Logout",
    path: "/logout",
    icon: <i className="icon fa-solid fa-arrow-right-from-bracket"></i>,
  },
];
