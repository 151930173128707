import { css } from "@emotion/react";

export const SettingsPageStyle = {
  index: css`
    height: 100%;
    overflow: auto;
    background-color: #fafafa;
  `,
  list: css`
    margin: 8px;
    .item-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
      margin-bottom: 0;
      padding: 14px;
      background-color: rgba(244, 236, 188, 0.3);
      select {
        border: 0;
        background-color: initial;
        text-align: right;
        color: rgba(0, 0, 0, 0.54);
        appearance: none;
        select::-ms-expand {
          display: none;
        }
      }
      span {
        &:first-of-type {
          text-transform: uppercase;
          font-size: 16px;
        }
        &:last-child {
          color: #bdbdbd;
        }
      }
      i {
        color: #137d33;
        font-size: 20px;
      }
      .list-chips {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .active {
          border: 2px solid #3fc274;
          border-radius: 100%;
          box-shadow: 3px 3px 9px #2abf2c !important;
          border-top-right-radius: 100% !important;
          border-bottom-right-radius: 100% !important;
        }

        img {
          width: 35px;
          height: 35px;
          object-fit: contain;
          margin-left: 5px;
        }
      }
      .check-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;

        .item-check-box {
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          input {
            width: 15px;
            height: 15px;
            font-size: 24px;
            line-height: 1;
            margin: 2px 4px 0;
          }
        }
        .title {
          text-transform: uppercase !important;
          max-width: inherit;
          font-weight: normal;
          text-align: left;
          font-size: 16px;
          span {
            color: blue;
            font-size: 10px;
          }
        }
      }
    }
  `,
};
