import { css } from "@emotion/react";

export const MenuGameMobileStyle = {
  wrapper: css`
    background-color: #f3f6f4;
  `,

  heading: css`
    height: 40px;

    background-color: #587fce;
    color: #fff;
    height: 40px;
    line-height: 34px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  `,

  menu: css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    padding: 10px 0;
  `,

  menuItem: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
    }

    span {
      color: #5680cd;
      font-weight: bold;
      font-size: 13px;
    }
  `,
};
