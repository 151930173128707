import { css } from "@emotion/react";

export const ResultTabStyle = {
  wrapper: css`
    padding: 0 8px;
    background-color: #fafafa;
  `,

  select: css`
    width: 100%;
    .ant-select {
      width: 100%;
      &-selector {
        background-color: #fafafa !important;
        border-radius: unset !important;
        border: unset !important;
      }
    }
  `,

  tabs: {
    index: css`
      padding: 0px 62px;
      height: 30px;
      display: flex;
    `,
    item: (isActive: boolean) => css`
      border: #137433 1px solid;
      color: ${isActive ? "white" : "#137433"};
      margin-left: -1px;
      min-height: auto;
      font-size: 12px;
      text-transform: uppercase;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${isActive ? "#5680cc" : ""};
      &:first-of-type {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      &:last-of-type {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    `,
  },

  listResult: css`
    overflow-y: scroll;
  `,
  selectGroup: css`
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    select {
      background-color: unset;
      border: unset;
      padding: 7px 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
      text-transform: uppercase;
      &:first-of-type {
        width: 50%;
      }
      &:last-of-type {
        width: 50%;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  `,

  selectHeading: css`
    background-color: #5680cc;
    color: white;
    padding: 7px 0 7px 5px;
    text-transform: uppercase;
    border: unset;
    width: 100%;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  `,

  outRightResult: {
    index: css`
      background-color: #fafafa;
      box-shadow: 0px 1px 1.5px rgba(0, 0, 0, 0.12);
      margin-top: 25px;

      p {
        padding: 8px 12px;
        font-size: 14px;
        color: black;
      }
    `,
    heading: css`
      padding: 12px 12px 0 12px;
      border-bottom: none;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      font-size: 16px;
      color: black;
    `,
  },

  normalResult: css``,
};
