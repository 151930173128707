/** @jsxImportSource @emotion/react */

import { LIST_CASINO_GAMES } from "constants/games";
import { DropdownGameStyle } from "./style.index";
import handleLoginGame from "helpers/handleLoginGame";
import { USERNAME } from "constants/user";

interface Props {
  isActive: boolean;
  setIsShow: (val: boolean) => void;
  setIsHidden: (val: boolean) => void;
}

const DropdownGame = ({ isActive, setIsShow, setIsHidden }: Props) => {
  const username = localStorage.getItem(USERNAME) || "";
  return (
    <div
      onMouseEnter={() => setIsShow(true)}
      onMouseLeave={() => setIsHidden(false)}
      css={DropdownGameStyle.wrapper(isActive)}
    >
      {LIST_CASINO_GAMES.map((item, index) => (
        <div
          onClick={() => handleLoginGame(item.data, username)}
          css={DropdownGameStyle.item}
          key={index}
        >
          <img src={item.img} alt="" />
          <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default DropdownGame;
