/** @jsxImportSource @emotion/react */

import { LIST_MENU_GAMES_MOBILE } from "constants/list-menu-game";
import { MenuGameMobileStyle } from "./style.index";
import { useNavigate } from "react-router-dom";
import { PATH } from "constants/path";

interface Props {
  setTypeGame: (type: string) => void;
}
const MenuGameMobile = ({ setTypeGame }: Props) => {
  const navigate = useNavigate();

  const onNavigate = (key: string, href: string) => {
    if (key === "keno") return;

    if (key === "live-casino") return setTypeGame("live");

    if (key === "lottery") return setTypeGame("lottery");

    if (key === "slot")
      return window.open(
        PATH.HomeMobile + PATH.SlotGame,
        "_blank",
        "noreferrer"
      );

    navigate(href);
  };

  return (
    <div css={MenuGameMobileStyle.wrapper}>
      <div css={MenuGameMobileStyle.heading}>Touch Version</div>
      <div css={MenuGameMobileStyle.menu}>
        {LIST_MENU_GAMES_MOBILE.map((item) => (
          <div
            onClick={() => onNavigate(item.key, item.to)}
            css={MenuGameMobileStyle.menuItem}
            key={item.key}
          >
            <img src={item.icon} alt="" />
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuGameMobile;
