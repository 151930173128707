import { css } from "@emotion/react";

export const LiveCasinoPageStyled = {
  wrapper: css`
    display: flex;
    flex-wrap: wrap;

    margin-top: 70px;
  `,

  item: css`
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 133px;
      max-width: 100%;
    }

    span {
      width: 100%;
      text-align: center;
      color: rgb(128, 0, 128);
      padding-top: 10px;
      font-weight: 700;
      line-height: 12px;
    }
  `,
};
