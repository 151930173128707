/** @jsxImportSource @emotion/react */

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ModalUseStyle } from "./style.index";
import { useEffect, useState } from "react";
import {
  ImageStep_1,
  ImageStep_2,
  ImageStep_3,
  ImageStep_4,
  ImageStep_5,
} from "assets/images/modals";
import ContentInner from "./ContentInner";

const LIST_HOW_TO_USE = [
  {
    img: ImageStep_1,
    title: "Select Product and Market",
    text: {
      rule_1: "1. First choose Sports / Game Type",
      rule_2:
        "2. Then choose Today / Live / Early / Date to display all leagues.",
    },
  },
  {
    img: ImageStep_2,
    title: "Select League",
    text: {
      rule_1:
        "- This function lists all the leagues under Sports / Game Type selected",
      rule_2:
        "- Tap on one or more leagues you prefer to show the matches & odds",
    },
  },
  {
    img: ImageStep_3,
    title: "Bet Slip",
    text: {
      rule_1:
        "- This function is only enabled when you have Multi Bets or Mix Parlay",
      rule_2:
        "- Tap on the icon above to display all the Multi Bet and Mix Parlay bet ticket combinations",
    },
  },
  {
    img: ImageStep_4,
    title: "MY FAVORITES",
    text: {
      rule_1:
        "1. To add / remove leagues or matches into Favorites, tap or untap the star icons next to them.",
      rule_2:
        "2. To display all your favorite leagues / matches, just tap on the star icon on the top right corner.",
      rule_3: "3. To leave the Favorite mode, untap the star icon again.",
    },
  },
  {
    img: ImageStep_5,
    title: "Live Match / Live Streaming",
    text: {
      rule_1:
        "1. This function lists all the matches provide Live Match or Live Streaming",
      rule_2:
        "2. Tap on the “Live Match” or “Live Streaming” button to watch and bet",
    },
  },
];

const TOTAL_STEPS = 5;

const ModalUse = () => {
  const queryClient = useQueryClient();

  const { data: isOpen } = useQuery<boolean>({
    queryKey: ["modal-use"],
    initialData: false,
  });

  const [isStep, setIsStep] = useState<number>(0);

  const onNext = () => {
    if (isStep === 4) return;
    setIsStep((prev) => prev + 1);
  };

  const onPrev = () => {
    if (isStep === 0) return;
    setIsStep((prev) => prev - 1);
  };

  const renderContent = () => {
    return <ContentInner {...LIST_HOW_TO_USE[isStep]} />;
  };

  useEffect(() => {
    setIsStep(0);
  }, [isOpen]);

  return (
    <div css={ModalUseStyle.wrapper(isOpen)}>
      <div css={ModalUseStyle.mask} />
      <div css={ModalUseStyle.modal}>
        <div
          onClick={onPrev}
          css={[ModalUseStyle.prev(isStep === 0), ModalUseStyle.control]}
        />

        <>{renderContent()}</>

        <div
          onClick={onNext}
          css={[ModalUseStyle.next(isStep === 4), ModalUseStyle.control]}
        />

        <div css={ModalUseStyle.footer}>
          <div css={ModalUseStyle.progress.index}>
            {Array.from(Array(TOTAL_STEPS)).map((_, index) => (
              <div
                key={index}
                css={ModalUseStyle.progress.dots(index === isStep)}
              />
            ))}
          </div>

          <div
            css={ModalUseStyle.btn}
            onClick={() => queryClient.setQueryData(["modal-use"], false)}
          >
            {isStep === 4 ? "Start" : "Skip"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUse;
