/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import { SportGameStyle } from "./style.index";
import { message } from "antd";
import { USERNAME } from "constants/user";
import { gameApi } from "api/game";
import axios from "axios";
import HelmetCustom from "components/common/HelmetCustom";
import Loading from "components/common/Loading";

const SportGame = () => {
  const source = axios.CancelToken.source();

  const username = localStorage.getItem(USERNAME) as string;

  const [url, setUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const queryLoginGameFn = async () => {
    try {
      setIsLoading(true);
      const res = await gameApi.loginGame(username, {
        username,
        provider: "AFBS",
        game_type: "SB",
        game_code: "",
        source,
      });
      setUrl(res.data.url);
      setIsLoading(false);
    } catch (error) {
      message.error(error + "");
    }
  };

  useEffect(() => {
    queryLoginGameFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={SportGameStyle.wrapper}>
      <HelmetCustom title="GT855" />
      {isLoading ? (
        <div css={SportGameStyle.loading}>
          <Loading height={"100%"} width={"100%"} />
        </div>
      ) : (
        <div css={SportGameStyle.content}>
          <iframe src={url} title="sport-game" width="100%" height="100%" />
        </div>
      )}
    </div>
  );
};

export default SportGame;
