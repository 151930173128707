/** @jsxImportSource @emotion/react */

import HeaderPage from "components/Mobile/HeaderPage";
import { MessagesPageStyle } from "./style";
import { useEffect, useId, useState } from "react";
import { AnnouncementApi } from "api/announcement";
import { message } from "antd";
import { AnnouncementItemType } from "types/announcement";
import moment from "moment";

function MessagesPage() {
  const idList = useId();
  const idScroll = useId();
  const [announcements, setAnnouncements] = useState<
    Array<AnnouncementItemType>
  >([]);

  const GetAnnouncements = async () => {
    try {
      const res = await AnnouncementApi.getAnnouncement({});
      setAnnouncements(res.data);
    } catch (error) {
      message.error(error + "");
    }
  };

  useEffect(() => {
    GetAnnouncements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={idList}
      onScroll={(e) => {
        const position = e.currentTarget.scrollTop;
        if (position > 12) {
          const element = document.getElementById(idScroll);
          if (!element) return;
          element.classList.add("animation");
        } else {
          const element = document.getElementById(idScroll);
          if (!element) return;
          element.classList.remove("animation");
        }
      }}
      css={MessagesPageStyle.index}
    >
      <HeaderPage title="MESSAGES" position="left" />
      <div css={MessagesPageStyle.tab_bar}>
        <p>Announcements</p>
      </div>
      <div css={MessagesPageStyle.announcement_list}>
        {announcements.map((item) => {
          return (
            <div className="list-group-item" key={item.id}>
              <div>
                <p>{item.title}</p>
                <p>{moment(item.start).format("YYYY/MM/DD hh:mm:ss a")}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        onClick={() => {
          document
            .getElementById(idList)
            ?.scrollTo({ top: 0, behavior: "smooth" });
        }}
        id={idScroll}
        css={MessagesPageStyle.scrollTop}
      >
        <i className="fa-solid fa-arrow-up"></i>
      </div>
    </div>
  );
}

export default MessagesPage;
