import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const CardGameStyle = {
  wrapper: css`
    width: 180px;
    height: 216px;
    margin: 8.5px;
    display: block;
    float: left;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    ${media.xxl} {
      width: 100%;
      margin: unset;
    }

    text-align: center;
    &:hover {
      .overflow {
        display: flex;
      }
    }

    span {
      line-height: 19px;
      height: auto !important;
      word-break: break-word;
      color: white;
      font-size: 16px;
    }
  `,

  img: css`
    display: block;
    height: 170px;
    border: 2px solid #fff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .hot {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 27px;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .overflow {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .image-card {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,

  btn: css`
    height: 30px;
    line-height: 30px;
    background-color: #353535;
    color: white;
    font-size: 20px;
    width: 100%;

    text-align: center;

    &:first-of-type {
      margin-bottom: 20px;
    }
  `,
};
