import { css } from "@emotion/react";

export const variablesCss = css`
  :root {
    --min-height-content-layout: 500px;
    --error-color: #ff4d4f;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
