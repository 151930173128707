import { css } from "@emotion/react";

export const BalancePopupStyle = {
  index: (isOpen: boolean) => css`
    background-color: #fff;
    position: fixed;
    bottom: calc(var(--bottom-height) + 4px);
    right: 0;
    height: auto;
    width: 85vw;
    max-height: 500px;
    z-index: 10;
    transition: all 0.15s ease-in-out;
    transform: translateX(${!isOpen ? "100vw" : "0px"});
    border-radius: 4px;
    /* padding: 5px 0; */
    padding: 2px 16px 2px 16px;
    .item-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      color: rgba(0, 0, 0, 0.87);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 8px 16px 8px 16px;
      line-height: 24px;
      min-height: 40px;
      &:last-child {
        border-bottom: 0;
      }
      span {
        &:nth-of-type(2) {
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  `,
  modal_backdrop: (isOpen: boolean) => css`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background-color: #000;
    opacity: 0.5;
    inset: 0;
    transition: all 1s;
    display: ${isOpen ? "block" : "none"};
  `,
};
