import { css } from "@emotion/react";

export const HomepageStyle = {
  wrapper: css`
    overflow-y: scroll;
  `,

  content: css`
    padding: 16px 10px;
    background: #d6d6d6;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    padding-bottom: 200px;
  `,
};
