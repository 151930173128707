export const QueryKeys = {
  // app
  isAuthenticated: "isAuthenticated",
  username: "username",
  // api
  MemberLogin: "MemberLogin",
  GetGameList: "GetGameList",
  MemberRegister: "MemberRegister",
  // user
  MemberDetail: "MemberDetail",
  MemberVIP: "MemberVIP",
  MemberBalance: "MemberBalance",
  Notification: "Notification",
  AddMemberBank: "AddMemberBank",
  GetMemberBanks: "GetMemberBanks",
  WithdrawForm: "WithDrawForm",
  WithdrawSubmission: "WithdrawSubmission",
  GetBankList: "GetBankList",
  DepositForm: "DepositForm",
  DepositMemberOrder: "DepositMemberOrder",
  WithDrawalMemberOrder: "WithDrawalMemberOrder",
  MemberDepositOrders: "MemberDepositOrders",
  MemberOrder: "MemberOrder",
  MemberOrders: "MemberOrders",
  Referral: "Referral",
  token: "token",
  PromotionList: "PromotionList",
  MemberPromotion: "MemberPromotion",
  Announcement: "Announcement",
  GetBalanceDV: "GetBalanceDV",
  UpdateMemberDetail: "UpdateMemberDetail",
  ForgotPassword: "ForgotPassword",
  //
  HotGameMobile: "HotGameMobile",
  Banner: "Banner",

  MemberMessage: "MemberMessage",
};
