import App from "App";
import { PATH } from "constants/path";
import { DefaultLayout } from "layouts/desktop/DefaultLayout";
import { PublicLayout } from "layouts/desktop/PublicLayout";
import DefaultLayoutMobile from "layouts/mobile/DefaultLayoutMobile";
import PublicLayoutMobile from "layouts/mobile/PublicLayoutMobile";
import LiveCasinoPage from "pages/desktop/Games/LiveCasino";
import SlotPage from "pages/desktop/Games/Slot";
import BetListPage from "pages/mobile/BetListPage";
import Homepage from "pages/mobile/Homepage";
import SettingsPage from "pages/mobile/SettingsPage";
import MessagesPage from "pages/mobile/MessagesPage";
import SlotGame from "pages/mobile/SlotGame";
import SportGame from "pages/mobile/SportGame";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import MyAccountPage from "pages/mobile/MyAccountPage";
import LotteryGame from "pages/desktop/Games/Lottery";
const HomePage = lazy(() => import("pages/desktop/HomePage"));
const LoginPage = lazy(() => import("pages/desktop/LoginPage"));
const ChangePassword = lazy(() => import("pages/desktop/ChangePassword"));
const LoginPageMobile = lazy(() => import("pages/mobile/LoginPage"));
const routesConfigDesktop: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          { path: PATH.Home, element: <HomePage /> },
          { path: PATH.LiveCasino, element: <LiveCasinoPage /> },
          { path: PATH.Lottery, element: <LotteryGame /> },
          { path: PATH.SlotGame, element: <SlotPage /> },
          { path: PATH.changePassword, element: <ChangePassword /> },
        ],
      },
      {
        element: <PublicLayout />,
        children: [{ path: PATH.Login, element: <LoginPage /> }],
      },
      {
        element: <DefaultLayoutMobile />,
        path: PATH.HomeMobile,
        children: [
          {
            path: PATH.HomeMobile,
            element: <Homepage />,
          },
          {
            path: PATH.HomeMobile + PATH.SPORT,
            element: <SportGame />,
          },

          {
            path: PATH.HomeMobile + PATH.SlotGame,
            element: <SlotGame />,
          },
          {
            path: PATH.HomeMobile + PATH.BetList,
            element: <BetListPage />,
          },
          {
            path: PATH.HomeMobile + PATH.User.setting,
            element: <SettingsPage />,
          },
          {
            path: PATH.HomeMobile + PATH.User.messages,
            element: <MessagesPage />,
          },
          {
            path: PATH.HomeMobile + PATH.User.index,
            element: <MyAccountPage />,
          },
        ],
      },
      {
        element: <PublicLayoutMobile />,
        path: PATH.HomeMobile,
        children: [
          {
            path: PATH.HomeMobile + PATH.Login,
            element: <LoginPageMobile />,
          },
        ],
      },
    ],
  },
];

export default routesConfigDesktop;
