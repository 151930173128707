import { css } from "@emotion/react";

export const SlotGameStyle = {
  wrapper: css`
    background-color: black;
    padding: 8px;

    overflow: scroll;
  `,

  listGame: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  `,
};
