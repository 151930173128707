import { css, keyframes } from "@emotion/react";

import { media } from "./breakpoints";

const scale = keyframes`
0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
}
50% {
    transform: scale(1);
    -webkit-transform: scale(1);
}
`;

const rotate = keyframes`
100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}
`;

export const reset = css`
  /* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */
  * {
    font-family: "Inter", sans-serif;
    margin: 0;
    padding: 0;
    zoom: reset;
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  a img {
    max-width: 100%;
    height: auto;
  }
  body {
    margin: 0;
    padding: 0px;
    font-size: 13px;
    font-family:
      Arial,
      Microsoft JhengHei;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ${media.md} {
    * {
      touch-action: manipulation;
      overscroll-behavior-y: none;
      font-size: 14px;
    }
    div {
      overscroll-behavior-y: none;
    }

    main {
      overscroll-behavior-y: none;
    }

    body {
      overscroll-behavior-y: none;
    }
    html {
      font-size: 125%;
    }
  }

  .center-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  *:root {
    --bottom-height: 55px;
  }

  .loading-app-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0;
    z-index: 1024;
    background-color: rgba(0, 0, 0, 0.2);

    &::before {
      content: "";
      width: 100px;
      height: 100px;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -50px;
      position: absolute;
      display: block;
      color: #fff;
      background-color: rgba(223, 223, 223, 0.8);
      border-radius: 15px;
    }

    .icon-loading {
      height: 30px;
      width: 30px;
      top: 48%;
      left: 50%;
      margin-top: 0px;
      margin-left: -15px;
      display: block;
      position: absolute;
      animation: ${rotate} 2s infinite linear;

      span {
        width: 30%;
        height: 30%;
        display: block;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.36);
        border-radius: 100%;
        animation: ${scale} 4s infinite ease-in-out;

        &:nth-of-type(2) {
          animation-delay: -1s;
          right: 0;
          top: 0;
        }

        &:nth-of-type(3) {
          animation-delay: -2s;
          right: 0;
          bottom: 0;
          left: auto;
          top: auto;
        }

        &:nth-of-type(4) {
          top: auto;
          bottom: 0px;
          animation-delay: -3s;
        }
      }
    }
  }
`;
