/** @jsxImportSource @emotion/react */

import handleLoginGame from "helpers/handleLoginGame";
import { CardGameMobileStyle } from "./style.index";
import { USERNAME } from "constants/user";
import { SubGameType } from "types/game";
import { useNavigate } from "react-router-dom";
import { PATH } from "constants/path";

interface Props {
  img: string;
  name: string;
  fit?: string;
  background?: string;
  data?: SubGameType;
}

const CardGameMobile = ({
  img,
  name,
  background,
  fit,
  data = {
    featured: "",
    game_code: "",
    name: "",
    new_arrive: "",
    picture: "",
    type: "LC",
    provider_code: "EVO",
  },
}: Props) => {
  const username = localStorage.getItem(USERNAME) || "";

  const navigate = useNavigate();

  const onLoginGame = (name: string, data: SubGameType) => {
    if (name === "Sports") return navigate(PATH.HomeMobile + PATH.SPORT);

    handleLoginGame(data, username);
  };

  return (
    <div
      onClick={() => onLoginGame(name, data)}
      css={CardGameMobileStyle.wrapper(background, fit)}
    >
      <img src={img} alt="" />
      <span>{name}</span>
    </div>
  );
};

export default CardGameMobile;
