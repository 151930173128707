import { css } from "@emotion/react";

export const MessagesPageStyle = {
  index: css`
    height: 100%;
    overflow: auto;
    background-color: #fff;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  tab_bar: css`
    position: sticky;
    top: 40px;
    background-color: #137433;
    height: 40px;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 9;
  `,
  announcement_list: css`
    margin: 8px;
    padding-bottom: 40px;
    .list-group-item {
      margin-bottom: 8px;
      border-radius: 4px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
      padding: 12px;
      background-color: rgba(244, 236, 188, 0.3);
      position: relative;
      display: block;

      p {
        color: rgb(255, 165, 0);
      }
    }
  `,
  scrollTop: css`
    position: fixed;
    bottom: 160px;
    right: 12px;
    width: 44px;
    height: 44px;
    background-color: rgba(131, 135, 140, 0.8);
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    transform: translateY(100vh);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.19);
    border: 2px solid #fff;
    i {
      font-size: 24px;
      color: white;
    }

    &.animation {
      transform: translateY(0px);
    }
  `,
};
