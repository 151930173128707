/** @jsxImportSource @emotion/react */

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MenuSideBarStyle } from "./style";
import { Fragment } from "react";
import { listMenus } from "./list-menu";
import { handleLogout } from "helpers/handleLogout";
import { useNavigate } from "react-router-dom";

function MenuSideBar() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: isOpen } = useQuery<boolean>({
    queryKey: ["open_sidebar"],
    initialData: false,
  });

  return (
    <Fragment>
      <div css={MenuSideBarStyle.index(isOpen)}>
        <div css={MenuSideBarStyle.listMenu}>
          <div className="main-bar">
            <div className="title">
              <span className="txtMore1">Menu</span>
            </div>
          </div>
          <div className="list">
            {listMenus.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    queryClient.setQueryData(["open_sidebar"], false);
                    if (item.path === "/logout") return handleLogout();
                    if (item.path === "to-use") {
                      queryClient.setQueryData(["modal-use"], true);
                      queryClient.setQueryData(["open_sidebar"], false);
                      return;
                    }
                    if (!item.path) return;
                    navigate(item.path);
                  }}
                  className="item-menu"
                >
                  {item.icon}
                  <span>{item.name}</span>
                  {item.rightIcon || null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        onClick={() => queryClient.setQueryData(["open_sidebar"], false)}
        css={MenuSideBarStyle.modal_backdrop(isOpen)}
      />
    </Fragment>
  );
}

export default MenuSideBar;
