/** @jsxImportSource @emotion/react */

import { ContentInnerStyle } from "./style.index";

interface Props {
  img: string;
  title: string;
  text: {
    rule_1: string;
    rule_2: string;
    rule_3?: string;
  };
}

const ContentInner = ({ img, text, title }: Props) => {
  return (
    <div css={ContentInnerStyle.wrapper}>
      <div css={ContentInnerStyle.img}>
        <img src={img} alt="" />
      </div>
      <div css={ContentInnerStyle.title}>
        <span>{title}</span>
      </div>
      <div css={ContentInnerStyle.text}>
        <span>{text.rule_1}</span>
        <br />
        <span>{text.rule_2}</span>
        {text.rule_3 && <br />}
        {text.rule_3 && <span>{text.rule_3}</span>}
      </div>
    </div>
  );
};

export default ContentInner;
