/** @jsxImportSource @emotion/react */

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, message } from "antd";
import { UserApi } from "api/user";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PopupChangeNameStyle } from "./style.index";
import { USERNAME } from "constants/user";
import { UpdateProfileResponse } from "types/user";

interface TypeForm {
  nickname: string;
}

interface Props {
  isOpen: boolean;
  isClose: () => void;
}

const PopupChangeName = ({ isClose, isOpen }: Props) => {
  const { t } = useTranslation("auth");
  const username = localStorage.getItem(USERNAME) || "";

  const schema = yup.object({
    nickname: yup
      .string()
      .trim()
      .required(t("validation.usernameRequired"))
      .required(),
  });

  const { refetch } = useQuery({
    queryFn: async () => {
      const res = await UserApi.getProfile({
        username,
      });
      return res.data;
    },
    queryKey: ["PROFILE_USER"],
    onError: (err) => {
      message.error(err + "");
      return err;
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (nickname: string) =>
      UserApi.updateMemberDetail(username, {
        username,
        nickname: nickname,
      }),
    onSuccess: (data: UpdateProfileResponse) => {
      message.success(data.msg);
      refetch();
      isClose();
    },
    onError: (error: any) => {
      message.error(error + "");
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TypeForm>({
    resolver: yupResolver<TypeForm>(schema),
    mode: "all",
  });

  const onSubmit = async (form: TypeForm) => {
    mutate(form.nickname);
  };

  return (
    <div css={PopupChangeNameStyle.wrapper(isOpen)}>
      <div css={PopupChangeNameStyle.content}>
        <div css={PopupChangeNameStyle.header} />
        <form onSubmit={handleSubmit(onSubmit)} css={PopupChangeNameStyle.form}>
          <div css={PopupChangeNameStyle.input}>
            <input {...register("nickname")} type="text" />
          </div>
          {errors.nickname && (
            <div css={PopupChangeNameStyle.errors}>
              {errors.nickname.message}
            </div>
          )}
          <p>
            <span>* </span>
            You can set nickname for display and login (5 to 15 alphabets or
            digits). Please note, you can set this nickname only once. You
            cannot modify or cancel it once it’s been set.
          </p>

          <div css={PopupChangeNameStyle.btn}>
            <Button htmlType="button" onClick={() => isClose()}>
              Cancel
            </Button>
            <Button loading={isLoading} htmlType="submit">
              ok
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupChangeName;
